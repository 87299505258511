import { makeStyles } from "@mui/styles";
export const useStyles = makeStyles((theme) => ({
  body: {
    backgroundColor: "#0b0b0b",
    width: "100%",
    overflowX: "hidden",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    fontFamily: "'Montserrat', sans-serif",
    
  },
}));
