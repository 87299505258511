import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { Video } from "./video";


const Hero = () => {
  const classes = useStyles();
  
  return (
    <>
      <Grid container className={classes.hero} id='home'
      sx={{alignItems:{md:"flex-end",sm:"flex-end",xs:"flex-start"}}}>
        <Video />

        <Grid
          container
          className={classes.textContainer}
          md={12}
          sm={12}
          xs={12}
          sx={{padding: {md:"100px 0px 60px 130px",sm:"150px 0px 0px 80px",xs:"100px 0px 60px 100px"}}}
        >
          <Grid item md={6} sm={12} xs={12}>
            <Typography className={classes.welcome} sx={{fontSize: {md:"18px",sm:"15px",xs:"12px"},}}>
              Welcome to AMProtocol Labs
            </Typography>
          </Grid>

          <Grid item md={10.5} my={{ md: 8, sm: 4, xs: 4 }}>
            <Typography
              sx={{
                fontSize: { md: "53px", sm: "40px", xs: "35px" },
                fontWeight: "700",
                lineHeight: {md:"64.61px",sm:"64.61px",xs:"40px"},
                textAlign: { md: "left", sm: "center", xs: "center" },
              }}
            >
              Revolutionizing the
              <span className={classes.glowText}> SaaS</span> landscape by
              building innovative solutions
            </Typography>
            <Grid mt={3} md={8} sm={12} xs={12}>
              <Typography
                sx={{
                  fontSize: { md: "20px", sm: "16px", xs: "12px" },
                  fontWeight: "500",
                  textAlign: { md: "left", sm: "center", xs: "center" },
                }}
              >
                We're dedicated to crafting solutions for both our clients and
                our own passions, delivering innovation on every front.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            sx={{
              display: "flex",
              justifyContent: { md: "flex-start", sm: "center", xs: "center" },
            }}
          >
            <Button href="/#services" className={classes.service} sx={{fontSize: { md: "14px", sm: "12px", xs: "10px" },}}>
              Browse Services
            </Button>
            <Button href="/pricing" className={classes.plans} sx={{fontSize: { md: "14px", sm: "12px", xs: "10px" }}}>
              Browse Plans
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Hero;
