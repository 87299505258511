import { Grid, Typography } from "@mui/material";
import { myApps } from "./applications";
import { useStyles } from "./useStyles";

const Develop = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.develop} sx={{ padding: {md:"50px 91px",sm:"50px 90px",xs:"50px 10px"},}}>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            className={classes.heading}
            sx={{ textAlign: { md: "center", sm: "center", xs: "center" },fontSize: {md:"40px",sm:"40px",xs:"25px"}, }}
          >
            Types of Applications We Can Develop
          </Typography>
        </Grid>
        <Grid className={classes.apps} item md={12} sm={12} xs={12} my={5}>
          {myApps?.map((item, index) => (
            <Grid
              className={classes.box}
              md={2.5}
              sm={5}
              xs={12}
              key={index}
              sx={{
                backgroundSize: {
                  md: "100% auto",
                  sm: "100% auto",
                  xs: "auto",
                },
              }}
            >
              <Grid className={classes.img}>
                <img src={item.img} alt="" />
              </Grid>
              <Grid className={classes.name} mt={2}>
                {item.name}
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item md={12} sm={12} xs={12}>
          <Typography
            className={classes.para}
            sx={{
              textAlign: {md:"left",sm:"justify",xs:"center"},
              fontSize: { md: "24px", sm: "24px", xs: "18px" },
              padding:{xs:"15px"}
            }}
          >
            We are quite confident in delivering apps of above types as most of
            our development team has years of experience centered around it.
            However, if you have a Saas app idea that is focused towards an area
            which is not mentioned above such as LMS (learning management
            system) or customer service system etc. we can definitely develop
            those too as our team possess the right mindset, knowledge, vision
            and resolve to find our way to delivering your needs.
          </Typography>
        </Grid>
      </Grid>
    </>
  );
};
export default Develop;
