import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";

const fullStack = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/fullStack.png";

const Stack = () => {
  const classes = useStyles();
  return (
    <Grid
      className={classes.stack}
      py={6}
      sx={{
        backgroundPosition: { md: "50% -5%", sm: "50% -10%", xs: "50% -20%" },
      }}
    >
      <Grid className={classes.header}>
        <Typography
          className={classes.heading}
          sx={{ fontSize: { md: "96px", sm: "75px", xs: "50px" } }}
        >
          Our Tech Stack
        </Typography>
      </Grid>
      <Grid
        className={classes.img}
        my={12}
        sx={{
          padding: { md: "0px 90px", sm: "80px 90px", xs: "50px 60px" },
          transform: { md: "scale(1)", sm: "scale(1.2)", xs: "scale(1.5)" },
        }}
      >
        <img src={fullStack} alt="" />
      </Grid>
    </Grid>
  );
};

export default Stack;
