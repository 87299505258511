import React from "react";
import { Grid, Typography } from "@mui/material";
import { partners } from "./partnerlist";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

const back = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/partner/back.png";
const middle = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/partner/middle.png";
const front = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/partner/front.png";

const Partner = () => {
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));
  return (
    <Grid
      id="promotion"
      sx={{
        width: "100%",
        height: "500px",
        backgroundColor: "#000",
        color: "#fff",
        padding: { md: "70px 70px", sm: "70px 30px", xs: "70px 10px" },
      }}
    >
      {/* <img src={partners} alt="Partner" /> */}
      <Typography
        sx={{
          fontSize: { md: "53px", sm: "53px", xs: "35px" },
          fontWeight: "700",
          lineHeight: "64.61px",
          textAlign: "center",
        }}
      >
        AMProtocol Lab’s Trusted{" "}
        <span style={{ color: "#FF6529" }}>Partners</span>
      </Typography>
      {/* <Grid
        container
        mt={10}
        sx={{
          backgroundImage: `url('${back}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100% auto",
          height: "fit-content",
          boxSizing: "border-box",
          position: "relative",
          width: "100%",
          height: "100px",
        }}
      >
        <Grid
          item
          md={12}
          sx={{
            backgroundImage: `url('${middle}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "100% auto",
            position: "absolute",
            top: "10px",
            height: "100px",
            width: "100%",
          }}
        >
          <Grid
            sx={{
              backgroundImage: `url('${front}')`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center center",
              backgroundSize: "100% auto",
              position: "relative",
              top: { md: "10px", sm: "10px", xs: "0px" },
              height: "140px",
              padding: { md: "20px 30px", sm: "10px 25px", xs: "10px 25px" },
              display: "flex",
              flexDirection:"row" ,
              justifyContent: {
                md: "space-around",
                sm: "space-evenly",
                xs: "center",
              }, //"space-around",
              alignItems: "center",
              boxSizing: "border-box",
            }}
          >
            {partners.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                sm={6}
                md={4}
                sx={{
                  backgroundImage: `url('${front}')`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center center",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  boxSizing: "border-box",
                  transform: {
                    md: "scale(1)",
                    sm: "scale(0.5)",
                    xs: "scale(0.4)",
                  },
                  // Additional styling to keep the components inside the parent Grid
                  margin: "10px", // Adjust the margin as needed
                  maxWidth: "80px", // Adjust the max width as needed
                }}
              >
                <a href={item.link}>
                  <img src={item.img} alt={item.name} />
                </a>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid> */}
      <Grid mt={9}
        sx={{
          backgroundImage: `url('${back}')`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "100% auto",
          height: "fit-content",
          boxSizing: "border-box",
          position: "relative",
          width: "100%",
          height: "100px",
        }}
      >
        <Grid sx={{
            backgroundImage: `url('${middle}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "100% auto",
            position: "absolute",
            top: "10px",
            height: "100px",
            width: "100%",}}>

        
        <Grid
          sx={{
            backgroundImage: `url('${front}')`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "100% auto",
            position: "relative",
            top: { md: "10px", sm: "10px", xs: "0px" },
            height: "fit-content",
            padding: { md: "40px 30px", sm: "30px 25px", xs: "20px 15px" },
            display: "flex",
            flexDirection: "row",
            justifyContent: {
              md: "space-around",
              sm: "space-evenly",
              xs: "center",
            },
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          {partners.map((item, index) => (
            <Grid
              key={index}
              item
              xs={12}
              sm={6}
              md={4}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                boxSizing: "border-box",
                width: "100%",
                margin: "10px",
              }}
            >
              <a href={item.link}>
                <img src={item.img} alt={item.name} style={{ width: "100%" }} />
              </a>
            </Grid>
          ))}
        </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Partner;
