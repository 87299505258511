import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  card: {
    width: "100%",
    color: "#fff",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    position: "relative",
    bottom: "20px",
    padding: "25px",
  },
  content: {
    height: "fit-content",
    width: "100%",
    position: "relative",
    zIndex: "2",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    textAlign: "center",
    color: "#fff",
    
  },
  stars: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  textHolder: {
    margin: "1px 0px",
    // padding: "40px",
    // fontSize: "14px",
    fontWeight: "400",
  },
  center: {
    display: "flex",
    justifyContent: "center",
  },
  name: { padding: "3%" },
  blank: {
    zIndex: "1",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: "20px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  full: { width: "100%", height: "100%" },
}));
