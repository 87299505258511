import NavBarDark from "../NavBars/NavBarDark/NavBarDark";
import Footer from "../Footer/Footer";
import Question from "./Question/Questions";

const FAQ = ()=>{
    return(
        <>
        <NavBarDark />
        <Question />
        <Footer pmd={"100"} psm={"20"} pxs={"20"} />
        </>
    );
};
export default FAQ;
