import { Grid } from "@mui/material";
import NavBar from "../NavBars/NavBarMain/NavBar";
import Hero from "./Hero/Hero";
import Clients from "./Clients/Clients";
import Results from "./Results/Results";
import Story from "./Story/Story";
import Services from "./Services/Services";
import WhyUs from "./WhyChooseUs/WhyUs";
import Achievments from "./Acheivments/Acheivments";
import Portfolio from "./Portfolio/Portfolio";
import Testimonial from "./Testimonials/Testimonial";
import Partner from "./Partner/Partner";
import Contact from "./Contact/Contact";
import Footer from "../Footer/Footer";

const HomePage = () => {

  return (
    <Grid>
      <NavBar />
      <Hero />
      <Clients />
      <Results />
      <Story />
      <Services />
      <WhyUs />
      <Achievments />
      <Portfolio />
      <Testimonial />
      <Partner />
      <Contact />
      <Footer pmd={"250"} psm={"350"} pxs={"350"}/>
    </Grid>
  );
};
export default HomePage;
