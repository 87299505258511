import { Grid, Typography } from "@mui/material";
const box = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/faqbox.png";
const ball1 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/faqball1.png";
const ball2 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/faqball2.png";

const Policy = () => {
    return (
        <>
            <Grid container
                sx={{
                    padding: { md: "150px 30px 100px", sm: "100px 20px", xs: "100px 10px " },
                    justifyContent: "center",
                    height: "100%",
                    background:"#030303",
                    backgroundImage: `url('${ball1}'), url('${ball2}')`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: { md: "100% 10%, 0% 110%", sm: "100% 10%, 0% 110%", xs: "top left,bottom right" },
                    backgroundSize: "auto, auto",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%"
                }}>
                <Grid item md={11} sm={11} xs={12}
                    sx={{
                        padding: {
                            md: "40px 40px 100px",
                            sm: "40px 40px 100px",
                            xs: "25px 10px",
                        },
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        flexDirection: "column",
                        alignItems: "center",
                        position: "relative",
                        background: "#0D0D0D1",
                        boxShadow: "0px 8px 5px 0px #00000040",
                        color: "#FFFF",
                        borderRadius: "15px",
                        overflow: "visible",
                        height: "fit-content",
                        
                        "&::before": {
                            content: "''",
                            position: "absolute",
                            inset: 0,
                            borderRadius: "15px",
                            padding: "1px",
                            background: "linear-gradient(145deg, white, transparent)",
                            WebkitMask:
                                "linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)",
                            WebkitMaskComposite: "xor",
                            maskComposite: "exclude",
                        },
                    }}
                >

                    <Typography
                        sx={{ fontSize: { md: "60px", sm: "80px", xs: "50px" }, fontWeight: "800", color: "#fff", textAlign: { md: "left", sm: "center", xs: "center" } }}
                    >
                        Privacy Policy
                    </Typography>

                    <Typography my={4}
                        sx={{ fontSize: "16px", fontWeight: "400", color: "#fff", textAlign: { md: "left", sm: "left", xs: "center" } }}
                    >
                        <span style={{ color: "#4e4f4f" }}>
                            Last Updated: 1st March 2024
                        </span>
                        <br />

                        At <a href="/" style={{ color: "#d94a14" }}>AMPROTOCOL LABS</a>, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and safeguard your information when you use our platform, FacoonLabs. By using our services, you consent to the practices described in this policy.
                        <br /> <br />
                        <span style={{ color: "#d94a14" }}>
                            1. Information We Collect Personal Information: {" "}
                        </span>
                        When you register on our platform, we may collect personal information such as your name, email address, and contact details.
                        <br />
                        Account Information:
                        We may collect information related to your account, including login credentials, profile details, and settings.
                        <br />
                        Usage Data:
                        We collect information about how you interact with our platform, including pages visited, features used, and other usage statistics.
                        <br />
                        Device Information:
                        We collect information about the device you use to access our platform, including IP address, browser type, and operating system.
                        <br />
                        Third-Party Data:
                        We may collect information from third-party services that you connect to our platform, such as social media networks and professional networking sites. This includes information such as profile data, connections, and activity. We only collect this data with your explicit consent.
                        <br /> <br />
                        <span style={{ color: "#d94a14" }}>
                            2. How We Use Your Information: {" "}
                        </span>
                        To Provide and Improve Services:
                        We use your information to provide, maintain, and improve our services, including personalizing your experience on our platform.
                        <br />
                        To Communicate with You:
                        We use your contact information to send you updates, notifications, and other information related to your use of our platform.
                        <br />
                        For Security Purposes:
                        We use your information to detect, prevent, and address security issues, fraud, and other malicious activities.
                        <br />
                        To Comply with Legal Obligations:
                        We may use your information to comply with applicable laws, regulations, and legal processes.
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            3. Sharing Your Information: {" "}
                        </span>
                        Service Providers:
                        We may share your information with third-party service providers who assist us in operating our platform and providing our services. These providers are contractually obligated to protect your information and use it solely for the purposes of providing the services.
                        <br />
                        Business Transfers:
                        In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of the transaction. We will notify you of any such change in ownership or control of your personal information.
                        <br />
                        Legal Requirements:
                        We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            4. Your Choices and Rights: {" "}
                        </span>
                        Access and Update Information:
                        You can access and update your account information through your profile settings on our platform.
                        <br />
                        Opt-Out of Communications:
                        You can opt-out of receiving promotional communications from us by following the unsubscribe instructions included in those communications or by contacting us directly.
                        <br />
                        Data Deletion:
                        You can request the deletion of your account and personal information by contacting us. Please note that certain information may be retained as required by law or for legitimate business purposes.
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            5. Data Security: {" "}
                        </span>
                        We implement appropriate technical and organizational measures to protect your information from unauthorized access, loss, misuse, or alteration. However, please be aware that no security measures are perfect or impenetrable, and we cannot guarantee the security of your information.
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            6. Third-Party Services: {" "}
                        </span>
                        Our platform may integrate with third-party services to enhance your experience, such as social media and professional networking sites. These services have their own privacy policies, and we encourage you to review them. We are not responsible for the privacy practices of third-party services.
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            7. Children's Privacy: {" "}
                        </span>
                        Our platform is not intended for use by individuals under the age of 18. We do not knowingly collect personal information from children under 18. If we become aware that we have inadvertently collected personal information from a child under 18, we will take steps to delete such information.
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            8. Consent and Data Collection:{" "}
                        </span>
                        We will not collect any personal data from you without your explicit consent. This includes data from third-party services such as social media and professional networking sites. You will be informed about the data being collected and its purpose, and your consent will be obtained before any data collection occurs.
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            9. Changes to This Privacy Policy:{" "}
                        </span>
                        We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our platform and updating the "Last Updated" date. Your continued use of our services after any changes indicates your acceptance of the updated Privacy Policy.
                        <br /><br />
                        <span style={{ color: "#d94a14" }}>
                            10. Contact Us:{" "}
                        </span>
                        If you have any questions or concerns about this Privacy Policy or our privacy practices, please contact us at:
                        <br />
                        <a href="/" style={{ color: "#d94a14" }}>AMPROTOCOL LABS</a>
                        <br />
                        Email: <a href="mailto:community@amprotocolabs.com" style={{ color: "#FFF" }}>community@amprotocolabs.com</a>
                        <br />
                        Address: <a href="https://www.google.com/maps/search/?api=1&query=R-331,+Sector+15-A/1,+Bufferzone,+Near+DC+Office" style={{ color: "#FFF" }} target="_blank">R-331, Sector 15-A/1, Bufferzone, Near DC Office</a>

                    </Typography>
                </Grid>

            </Grid>
        </>
    )
}
export default Policy;