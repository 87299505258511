const anab = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/anab.png";
const saad = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/saad.png";
const nabeel = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/nabeel.png";
const hamza = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/hamza.png";
const ahsan = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/ahsan.png";
const arslan = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/arslan.png";
const ayesh = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/ayesh.png";
const zain = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/zain.png";
const fawad = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/fawad.png";
const rameez = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/rameez.png";
const humna = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/humna.png";
const zehra = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/zehra.png";
const nimra = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/circle/nimra.png";


const Nabeel ="https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/nabeelpng.png";
const Anab =  "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/anab.png";
const Ahsan = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/ahsan.png";
const Arslan ="https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/arslan.png";
const Ayesh = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/ayesh.png";
const Rameez ="https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/rameez.png";
const Fawad = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/Fawad.png";
const Zain =  "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/zain.png";
const Humna = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/humna.png";
const Zehra = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/zehra.png";
const Nimra = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/nimra.png";
const Hamza = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/Team/b_w/hamza.png";



export const members=[
    {
        id:1,
        fname:"SYED",
        lname:"ANAB IMAM",
        img:anab,
        display:Anab,
        about:"Syed Anab Imam is a software engineer with over 10+ years of experience in Web Development. He has worked extensively in Backend Development with focus on developing scalable applications. His experience ranges from working as a core member of development teams in various companies and leading in different capacities. He is currently focused on leading AMProtocol Labs as CEO and its Lead Engineer.",
        fb:"https://www.facebook.com/syed.imam.378",
        linkedIn:"https://www.linkedin.com/in/syed-anab-imam-63972a56/?originalSubdomain=pk",
        github:"https://github.com/syedanabimam",
        info:"CEO",
        info2:"CTO",
        
    },
    
    {
        id:2,
        fname:"NABEEL", 
        lname:"AHMED MASOODI",
        img:nabeel,
        display:Nabeel,
        about:"Meet Nabeel Ahmed, an accomplished engineer with a background in Electronic Engineering. Nabeel's journey took an unexpected turn into the realms of UI and UX design, where he seamlessly blends his technical prowess with creative flair. As a UI/UX engineer, Nabeel crafts not only intricate circuit diagrams but also visually compelling and user-centric digital experiences.",
        fb:"https://www.facebook.com/hbpnam",
        linkedIn:"https://www.linkedin.com/in/hbpnma",
        github:"https://github.com/Hbpnma",
        info:"Lead Designer",
        info2:"UI/UX",
    },
    
    {
        id:3,
        fname:"RAMEEZ", 
        lname:"ARIF",
        img:rameez,
        display:Rameez,
        about:"Rameez Arif, A media science graduate. With an impressive four years of hands-on experience, Rameez Arif is a highly skilled video editor, graphics, and motion designer. Armed with a passion for bringing ideas to life through captivating visuals. Join Rameez Arif on a journey where creativity meets precision, and every frame tells a story.",
        fb:"https://www.facebook.com/Rameeezarif",
        linkedIn:"https://www.linkedin.com/in/hbpnma",
        github:"https://github.com/Hbpnma",
        info:" Graphic Designer",
        info2:"UI/UX",
    },
    
    {
        id:4,
        fname:"SYED MUHAMMAD ",
        lname:"HAMZA",
        img:hamza,
        display:Hamza,
        about:"Meet Hamza, an accomplished engineer specializing in Frontend Development with a focus on React. Armed with a solid background in Electronic Engineering, my professional journey took an unexpected and exciting turn into the dynamic world of web development. As a Frontend React Engineer, I seamlessly merge my technical expertise with a creative flair to craft engaging and innovative user interfaces. With a passion for transforming ideas into functional, aesthetically pleasing applications, I thrive on the challenges of code optimization, responsive design, and delivering seamless user experiences. Join me on this journey where technology meets creativity, and let's build exceptional digital experiences together.",
        fb:"https://www.facebook.com/Hamza.smh7200",
        linkedIn:"https://www.linkedin.com/in/syed-muhammad-hamza-1822a7209",
        github:"https://github.com/hamza-smh",
        info:"Frontend Engineer",
    },
        
    {
        id:5,
        fname:"ARSLAN ",
        lname:"AHMED",
        img:arslan,
        display:Arslan,
        about:"Bringing five years of Ruby on Rails expertise, he's celebrated for mastery and a resilient 'can-do' attitude. His extensive experience, combined with his perfection as a tech lead, underscores his remarkable proficiency.",
        fb:"https://www.facebook.com/profile.php?id=100005216348874",
        linkedIn:"https://www.linkedin.com/in/arslan-ahmad-forrentech/",
        github:"https://github.com/arslanahmad05",
        info:"Lead Backend Engineer",
        
    },
    
    {
        id:10,
        fname:"ZEHRA", 
        lname:"",
        img:zehra,
        display:Zehra,
        about:"A passionate designer specializing in UI/UX, I weave a narrative of innovation through pixels. With a keen eye for aesthetics and a love for all things visual, I bring dreams to life, one design at a time, crafting Seamless UI/UX Experiences.",
        fb:"",
        linkedIn:"",
        github:"",
        info:"Junior Graphic Designer",
        info2:"UI/UX",
    },
    {
        id:6,
        fname:"AYESH",
        lname:"BHATTI",
        img:ayesh,
        display:Ayesh,
        about:"Ayesh Bhatti ,a Junior Backend Developer at AMProtocol Labs, I wield a passion for transforming intricate code into seamless digital experiences. Building a command on Ruby on Rails, I navigate databases and server-side complexities with precision. Dedicated to continuous learning, I bring a dynamic approach to coding, ensuring robust and efficient solutions that drive our digital evolution.",
        fb:"https://www.facebook.com/ayesh.bhatti.92?mibextid=LQQJ4d",
        linkedIn:"https://www.linkedin.com/in/ayesh-bhatti-456821269",
        github:"https://github.com/ayeshbhatti",
        info:" Junior Backend Engineer",    
    },
    
    {
        id:8,
        fname:"HUMNA ",
        lname:"KHALID",
        img:humna,
        display:Humna,
        about:"Humna Khalid, a Pharmacist with 5 years of experience in Business Development, has transitioned into Product Management. Having successfully navigated the development of both pharmaceutical and software products, she skillfully combines her expertise to bring diverse projects from inception to successful completion.",
        fb:"https://www.facebook.com/humna.k.bhatti?mibextid=ZbWKwL",
        linkedIn:"https://www.linkedin.com/in/humna-khalid-9ab56b184?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
        github:"",
        info:"Product Manager",
        
    },
    {
        id:7,
        fname:"ZAIN",
        lname:"KAMRAN",
        img:zain,
        display:Zain,
        about:" I am Zain Kamran, I am a Business Development Executive. I Drive business growth as a key member of the development team. Experienced Business Development Executive passionate about forging strategic partnerships and fostering sustainable business growth.",
        fb:"https://www.facebook.com/Zain.Kamran.Official",
        linkedIn:"https://www.linkedin.com/in/muhammad-zain-ul-abideen-0fficial/",
        github:"",
        info:"Business Development Executive",    
    },

    
    {
        id:9,
        fname:"FAWAD",
        lname:"HUSSAIN",
        img:fawad,
        display:Fawad,
        about:"Fawad Hussain is a dedicated Public Administration student and a Business Development Executive at AMProtocol Labs, boasting 8 months of expertise. Passionate about merging administrative skills with innovative business strategies.",
        fb:"",
        linkedIn:"",
        github:"",
        info:"Business Development Executive",    
    },
    
     
    {
        id:6,
        fname:"NIMRA",
        lname:"",
        img:nimra,
        display:Nimra,
        about:"Nimra Shahzad , Senior Business Development Executive with a knack for spotting untapped markets and forging meaningful connections , thrives on creating strategic partnerships and driving growth. With extensive years of experience in sales. I excel at identifying new business opportunities , building strong relationships with clients.",
        fb:"",
        linkedIn:"",
        github:"",
        info:"Business Development Executive",    
    },
    
    // {
    //     id:3,
    //     fname:"SYED MUHAMMAD ",
    //     lname:"YOUNUS",
    //     img:'',
    //     display:"",
    //     about:"About YOUNUS",
    //     fb:"",
    //     linkedIn:"",
    //     github:"",
    //     info:"Product Manager",
        
    // },










    //     {
//     id:2,
//     fname:"MUHAMMAD",
//     lname:"SAAD HAIDER",
//     img:saad,
//     display:saad,
//     about:"About Saad",
//     fb:"",
//     linkedIn:"",
//     github:"",
//     info:"Lead Frontend Engineer",
// },
// {
//     id:3,
//     fname:"MUHAMMAD ",
//     lname:"AHSAN NASEEM",
//     img:ahsan,
//     about:"About Ahsan",
//     fb:"",
//     linkedIn:"https://www.linkedin.com/in/ahsann/",
//     github:"",
//     info:"Co-Founder",
//     info2:"CMO",
// },

]
