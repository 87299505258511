import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStyles } from "./useStyles";

const design = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Design team-bro_1.gif";

const Story = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <>
      <Grid className={classes.root}>
        <Grid
          container
          className={classes.dark}
          sx={{
            justifyContent: { md: "left", sm: "center", xs: "center" },
            padding: isXs ? "15px" : "60px",
          }}
        >
          <Typography
            className={classes.heading}
            sx={{
              textAlign: { md: "left", sm: "center", xs: "center" },
              fontSize: {md:"53px",sm:"53px",xs:"35px"},
            }}
          >
            Our Story
          </Typography>
          <Typography
            className={classes.subHeading}
            sx={{
              paddingRight: { md: "250px", sm: "0px", xs: "0px" },
              textAlign: { md: "left", sm: "center", xs: "center" },
              fontSize: { md: "32px", sm: "32px", xs: "20px" },
            }}
          >
            Experience Trustworthy SaaS Development from Partners Who Build
            Their Own Successful SaaS Products
          </Typography>
          <Grid item md={8} my={6}>
            <Typography
              className={classes.text}
              sx={{
                textAlign: { md: "left", sm: "justify", xs: "justify" },
              }}
            >
              We at AMProtocol Labs are keen on building out innovative
              solutions that serve the needs of software products and custom
              software development projects. Founded in January 2023 by founders
              who are experienced in Web Development, Mobile Development and
              other variety of technologies to build out custom software
              solutions. Our main focus is on developing our own SaaS products
              as well as helping other with their software needs. With a perfect
              blend of Founders who are both Software Engineers, Data Analysts
              as well as Digital Marketers, we are realistic in formulating our
              approach, ensuring what we are developing is Extensible,
              Maintainable, Scalable and most importantly Marketable. Please
              check our portfolio of our own SaaS products as well as custom
              software projects we have worked on for our clients. We would love
              to hear more about your queries via our Contact Page.
              <br />
              <br />
              Please check our work in{" "}
              <a
                href="/#portfolio"
                style={{
                  color: "#D25627",
                  textDecoration: "none",
                  fontWeight: "bold",
                }}
              >
                here
              </a>{" "}
              and our{" "}
              <a
                href="/pricing"
                style={{
                  color: "#D25627",
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                Pricing page
              </a>{" "}
              to learn more.
            </Typography>
            <Grid
              item
              my={6}
              sx={{
                display: "flex",
                justifyContent: { md: "left", sm: "center", xs: "center" },
              }}
            >
              <Button
                variant="contained"
                size="large"
                className={classes.button}
                href="/technology"
              >
                See what we work on
              </Button>
            </Grid>
          </Grid>
          <Grid md={4}>
            <img src={design} alt="" 
            style={{
            width: isXs ? "100%" : "auto",
            height: isXs ? "100%" : "auto",
          }}/>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Story;
