const crm = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/crm.png";
const erp = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/erp.png";
const analytics = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/analytics.png";
const billing = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/billing.png";
const hr = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/hr.png";
const logistics = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/logistics.png";
const project = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/project.png";
const ecommerce = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/ecommerce.png";

export const myApps = [
  {
    id: 1,
    name: "CRM",
    img: crm,
  },
  {
    id: 2,
    name: "ERP",
    img: erp,
  },
  {
    id: 3,
    name: "Billing",
    img: billing,
  },
  {
    id: 4,
    name: "Data Analtyics",
    img: analytics,
  },
  {
    id: 5,
    name: "HR",
    img: hr,
  },
  {
    id: 6,
    name: "Ecommerce",
    img: ecommerce,
  },
  {
    id: 7,
    name: "Project Management",
    img: project,
  },
  {
    id: 8,
    name: "Logistics",
    img: logistics,
  },
];
