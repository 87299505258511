import { makeStyles } from "@mui/styles";

const question = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/question.png";
const box = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/faqbox.png";
const ball1 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/faqball1.png";
const ball2 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/faqball2.png";

export const useStyles = makeStyles((theme) => ({
  faq: {
    height: "100%",
    backgroundImage: `url('${ball1}'), url('${ball2}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top right, -2% 125%",
    backgroundSize: "auto, auto",
    flexDirection: "column",
    alignItems: "center",
    width:"100%"
  },
  heading: {
    display: "flex",
    justifyContent: "center",
    fontSize: "53px",
    fontWeight: "700",
    color: "#fff",
  },
  box: {
    backgroundImage: `url('${box}')`,
    backgroundRepeat: "no-repeat",
    
    backgroundSize: "100% 100%",
    height: "100%",
  },
  map: {
    
  },
  holder: {
    backgroundColor: "transparent",
    backdropFilter: "blur(22px)",
    border: "none",
    boxShadow: "none",
    height: "100%",
  },
  arrow: {
    transform: "scale(2.5)",
  },
  question: {
    backgroundColor: "transparent",
    height: "fit-content",
    backgroundImage: `url('${question}')`,
    backgroundSize: "100% 100%",
    backgroundRepeat: "no-repeat",
  },
  text: {
    color: "#fff",
    fontWeight: "500",
  },
  answer: {
    height: "100%",
    width: "100%",
    position: "relative",
    zIndex: -1,
    padding: "0px",
    background: "transparent",
    bottom: "30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  orange: {
    background:
      "linear-gradient(90deg, rgba(209, 63, 8, 0.77) 0%, rgba(230, 90, 36, 0.77) 100%)",
    borderRadius: "15px",
    height: "100%",
    position: "relative",
    zIndex: -1,
    width: "95%",
    
    textAlign: "center",
    backdropFilter: "blur(22px)",
    border: "1px solid #fff",
  },
}));
