import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  white: { color: "#fff" },
  test: {
    
    fontWeight: "500",
  },
  happy: {
    
    fontWeight: "700",
  },
  row: { display: "flex", flexDirection: "row" },
  buttonHolder: {
    display: "flex",
    position: "relative",
    alignItems: "center",
  },
  button: {
    zIndex: 5,
    "&:hover": {
      backgroundColor: "transparent",
    },
    "&:focus": {
      outline: "none",
    },
  },
}));
