const WebDevelopment = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/services/webTemplate.png";
const Aggregation = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/services/Aggregation icon.png";
const Api = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/services/Api icon.png";
const Design = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/services/logo.png";
const Mobile = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/services/mobile.png";
const Vector = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/services/custom Web.png";


export const serviceList = [
  {
    icon: Vector,
    title: "Custom Web Development",
    text: "Get a tailor-made, fully functional website crafted to perfection.",
  },
  {
    icon: Api,
    title: "API Integration",
    text: "Seamless third-party API integrations for streamlined data flow.",
  },
  {
    icon: Aggregation,
    title: "Aggregation Tools ",
    text: "Robust tools to collect, analyze, and manage information from various sources.",
  },
  {
    icon: WebDevelopment,
    title: "Web Templating",
    text: "Eye-catching, responsive website templates for various industries.",
  },
  {
    icon: Mobile,
    title: "Mobile App Development ",
    text: "Engaging and intuitive mobile apps for iOS and Android platforms.",
  },
  {
    icon: Design,
    title: "Logo Design",
    text: "Creative and memorable logo designs to establish a strong brand identity.",
  },
];
