import React, { useState } from "react";
import axios from "axios";

import {
  Grid,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useStyles } from "./useStyles";

const Contact = () => {
  const classes = useStyles();
  const array = Array.from({ length: 4 }, (_, index) => index);

  const [agreeToProcess, setAgreeToProcess] = useState(false);
  const [receiveNewsletters, setReceiveNewsletters] = useState(false);
  const [formData, setFormData] = useState({
    fname: "",
    email: "",
    contact: "",
    company: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
//const apiKey = process.env.d947386be887b30a602d7287b3106a41-us9;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        "https://us9.api.mailchimp.com/3.0/lists/308995c375/members",
        {
            email_address: formData.email,
            contact: formData.contact,
            company: formData.company,
            status: "subscribed",
            merge_fields: {
              FNAME: formData.fname,
              MESSAGE: formData.message,
            },
          
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: 'apikey d947386be887b30a602d7287b3106a41-us9',
          },
        }
      );

      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const isSubmitDisabled = !agreeToProcess;

  const handleAgreeToProcessChange = () => {
    setAgreeToProcess((prev) => !prev);
  };

  const handleReceiveNewslettersChange = () => {
    setReceiveNewsletters((prev) => !prev);
  };

  return (
    <>
      <Grid className={classes.contact} container id="contact">
        <Grid className={classes.talk}>
          {array.map((index) => (
            <Grid className={classes.textHolder} key={index}>
              <Typography
                className={classes.text}
                sx={{ fontSize: { md: "113px", sm: "83px", xs: "64px" } }}
              >
                Let's Talk
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid
          className={classes.body}
          item
          md={9}
          sm={11}
          xs={12}
          sx={{ padding: { md: "40px", sm: "40px", xs: "40px 15px" } }}
        >
          <Typography
            className={classes.heading}
            sx={{ fontSize: { md: "53px", sm: "53px", xs: "35px" } }}
          >
            We’d love to hear from you
          </Typography>
          <form onSubmit={handleSubmit}>
            <Grid container>
              <Grid item md={7}>
                <TextField
                  required
                  id="outlined-basic"
                  label="Full Name"
                  name="fname"
                  value={formData.fname}
                  onChange={handleChange}
                  defaultValue=""
                  variant="outlined"
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    mr: 1,
                  }}
                />
                <TextField
                  required
                  id="outlined-basic"
                  label="Email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  defaultValue=""
                  variant="outlined"
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    ml: 1,
                  }}
                />
                <TextField
                  required
                  id="outlined-basic"
                  label="Contact Number"
                  name="contact"
                  value={formData.contact}
                  onChange={handleChange}
                  defaultValue=""
                  variant="outlined"
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    mr: 1,
                  }}
                />
                <TextField
                  id="outlined-basic"
                  label="Company"
                  name="company"
                  value={formData.company}
                  onChange={handleChange}
                  defaultValue=""
                  variant="outlined"
                  className={classes.input}
                  sx={{
                    my: { md: 2, sm: 2, xs: 2 },
                    ml: 1,
                  }}
                />
                <TextField
                  id="outlined-multiline-static"
                  required
                  fullWidth
                  label="Message"
                  name="messsage"
                  value={formData.msg}
                  onChange={handleChange}
                  multiline
                  rows={4}
                  className={classes.inputMsg}
                  sx={{
                    my: 2,
                  }}
                />
              </Grid>
              <Grid
                item
                md={5}
                sm={12}
                xs={12}
                className={classes.p40}
                sx={{ padding: { md: "40px", sm: "40px", xs: "30px 15px" } }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={agreeToProcess}
                      onChange={handleAgreeToProcessChange}
                      style={{ color: "#fa4d02" }}
                    />
                  }
                  label={
                    <Typography className={classes.agree}>
                      I agree to process personal data
                    </Typography>
                  }
                  size="small"
                  className={classes.f20}
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      className={classes.checkbox}
                      checked={receiveNewsletters}
                      onChange={handleReceiveNewslettersChange}
                      style={{ color: "#fa4d02" }}
                    />
                  }
                  label={
                    <Typography className={classes.agree}>
                      I agree to receive newsletters and promotional emails
                    </Typography>
                  }
                  size="small"
                  className={classes.f20}
                />
                <Grid
                  mt={{ md: 6, sm: 2, xs: 2 }}
                  sx={{
                    display: "flex",
                    justifyContent: { md: "left", sm: "left", xs: "center" },
                  }}
                >
                  <Button
                    type="submit"
                    disableRipple
                    disableElevation
                    className={classes.submit}
                    disabled={isSubmitDisabled}
                    sx={{
                      backgroundColor: isSubmitDisabled ? "#6e2201" : "#A6360B",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </>
  );
};
export default Contact;
