import { makeStyles } from "@mui/styles";

const ball = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/redball_bg.png";
const blurRed = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/blurRed.png";

export const useStyles = makeStyles((theme) => ({
  blurBack: {
    backgroundImage: `url('${blurRed}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "-100px -60px",
    
  },
  process: {
    margin: "60px 0px 0px 0px",
  },
  text: {
    
    fontWeight: "700",
    textAlign: "center",
  },
  result: {
    color: "#D25627",
  },
  imgHolder: {
    display: "flex",
    justifyContent: "center",
  },
  holder: {
    display: "flex",
    flexDirection: "column",
    zIndex: 2,
    backgroundImage: `url('${ball}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    textAlign: "center",
    width: "fit-content",
  },
}));
