const projects = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Achievments/projects.png";
const saas = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Achievments/saas.png";
const adoption = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Achievments/adoption.png";
const clients = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Achievments/clients.png";
const revenue = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Achievments/revenue.png";

export const achievementList = [
    {
        id:1,
        img:clients,
        value: "300+",
        caption:"Happy Clients"
    },
    {
        id:2,
        img:projects,
        value:'50',
        caption:"Projects Completed"
    },
    {
        id:3,
        img:adoption,
        value:"47",
        caption:"Adoption Rate"
    },
    {
        id:4,
        img:revenue,
        value:"$57k",
        caption:"Revenue Generated"
    },
    {
        id:5,
        img:saas,
        value:"02",
        caption:"SaaS Products"
    }
]