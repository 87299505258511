import { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import Steps from "./Step/Step";
import SelectPlan from "./SelectPlan/SelectPlan";
import Checkout from "./Checkout/Checkout";
import Scheme from "../Scheme/Scheme";

const Details = () => {
  const [planSelect, setPlanSelect] = useState(1);
  const [schemeSelect, setSchemeSelect] = useState("");
  const [stage, setStage] = useState("Scheme");
const [newStage, setNewStage] = useState('');

  const handlePlanSelect = (index) => {
    setPlanSelect(index);
  };
  const handleSchemeSelect = (index) => {
    setSchemeSelect(index);
  };

  const goToSelectPlan = () => {
    setStage("SelectPlan");
  };

  const goToCheckout = () => {
    setStage("Checkout");
  };

  const goToScheme = () => {
    setStage("Scheme");
  };
console.log(newStage);
  return (
    <Grid>
      {stage === "Scheme" ? (
        <Scheme
          firstChoice={handleSchemeSelect}
          onToSelectPlan={goToSelectPlan}
        />
      ) : stage === "SelectPlan" ? (
        <SelectPlan
          choice={handlePlanSelect}
          atFirst={schemeSelect}
          onToCheckout={goToCheckout}
          onToScheme={goToScheme}
          stage={newStage}
        />
      ) : stage === "Checkout" ? (
        <Checkout 
          choice={planSelect} 
          onToSelectPlan={goToSelectPlan} />
      ) : (
        ""
      )}
    </Grid>
  );
};
export default Details;
