import React from "react";
import { Grid, Typography } from "@mui/material";
import { achievementList } from "./AcheivementsList";
import { useStyles } from "./useStyles";

const ribbonFront = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/ribbon-front.png";

const Achievments = () => {
  const classes = useStyles();
  return (
    <>
      <Grid className={classes.main}>
        <Typography className={classes.achieve} sx={{fontSize: {md:"53px",sm:"53px",xs:"35px"},}}>Achievements</Typography>
        <Typography
          className={classes.text}
          sx={{ padding: { md: "0px 30%", sm: "0px 15%",xs:"0px 5%" },fontSize: {md:"21px",sm:"21px",xs:"16px"}, }}
        >
          We pride ourselves on providing best SaaS services to our clients. Our
          achievements include:
        </Typography>
        <Grid className={classes.list} mt={8}>
          {achievementList.map((item, index) => (
            <Grid key={index}>
              <img src={item.img} alt="" />
              <Typography className={classes.name}>{item.value}</Typography>
              <Typography className={classes.caption}>
                {item.caption}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Grid className={classes.ribbon} item mt={12} mx={0} >
          <img src={ribbonFront} alt=" " style={{width:"100%"}}/>
        </Grid>
      </Grid>
    </>
  );
};
export default Achievments;
