import { Grid } from "@mui/material";
import NavBar from "../NavBars/NavBarMain/NavBar";
import Footer from "../Footer/Footer";
import WhatWeWork from "./WhatWeWork/WhatWeWork";
import Develop from "./Develop/Develop";
import Stack from "./Stack/Stack";


const TechStack = () => {
  return (
    <Grid>
      <NavBar />
      <WhatWeWork />
      <Develop />
      <Stack />
      <Footer pmd={"150"} psm={"150"} pxs={"150"} />;
    </Grid>
  );
};
export default TechStack;
