import { makeStyles } from "@mui/styles";

const bigBall = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/bigBall.png";

export const useStyles = makeStyles((theme) => ({
  selectPlan: {
    backgroundImage: `url('${bigBall}')`,
    backgroundPosition: "bottom right",
    backgroundRepeat: "no-repeat",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "start",
  },
  header: { color: "#fff", padding: "30px 0px" },
  heading: { fontWeight: "800" },
  subHeading: {  },
  body: { display: "flex", justifyContent: "space-between" },
  planButton: {
    color: "#fff",
    borderImageSource:
      "linear-gradient(311.65deg, #FFFFFF 10.22%, rgba(255, 255, 255, 0) 90.38%),2",
    borderRadius: "15px",
    
    marginBottom: "20px",
    boxShadow: "0px 0px 20px 4px #0000000D",
    width: "100%",
    height: "fit-content",
    "&:focus": { outline: "none" },
  },
  scheme: {
    display: "flex",
    flexDirection: "column",
    textTransform: "capitalize",
  },
  title: { fontSize: "25px" },
  rcmnd: { fontSize: "12px" },
  about: { padding: "10px 0px", fontSize: "12px" },
  price: { fontSize: "28px" },
  lowercase: { fontSize: "16px", textTransform: "lowercase" },
  bill: {
    background:
      "linear-gradient(90deg, rgb(64,64,64,0.45) 6.67%, rgba(64, 64, 64, 0) 100%)",
    backdropFilter: "blur(11px)",
    color: "#fff",
    "&:focus": { outline: "none" },
    borderRadius: "36px",
    padding: "60px 50px",
    marginBottom: "20px",
    width: "100%",
    minHeight: "100%",
    border: "1px solid #fff",
  },
  summary: { fontSize: "24px" },
  titlePlan: { padding: "15px 0px" },
  pricePlan: { textAlign: "right" },
  divider: { background: "#FFFFFF" },
  due: { fontWeight: "400", fontSize: "20px" },
  right: { textAlign: "right" },
  left: { textAlign: "left" },
  buttonHolder: {
    display: "flex",
    justifyContent: "center",
  },
  continue: {
    color: "#fff",
    backgroundColor: "#D25627",
    borderRadius: "30px",
    textTransform: "capitalize",
    padding: "10px 30px",
    fontSize: "20px",
    "&:hover": {
      border: "1px solid #D25627",
    },
  },
}));
