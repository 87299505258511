import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
} from "@mui/material";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { faq } from "./QuestionList";
import { useStyles } from "./useStyles";

const AccordionList = () => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(Array(faq.length).fill(false));

  const handleChange = (index) => {
    const newExpanded = [...expanded];
    newExpanded[index] = !newExpanded[index];
    if (newExpanded[index]) {
      for (let i = 0; i < newExpanded.length; i++) {
        if (i !== index) {
          newExpanded[i] = false;
        }
      }
    }
    setExpanded(newExpanded);
  };

  useEffect(() => {
    setExpanded((prevExpanded) => {
      const newExpanded = [...prevExpanded];
      newExpanded[0] = true;
      return newExpanded;
    });
  }, []);

  return (
    <>
      <Grid
        container
        className={classes.faq}
        sx={{
          padding: { md: "100px", sm: "100px 50px", xs: "100px 10px" },
        }}
      >
        <Grid mb={6} className={classes.heading}>
          <Typography variant="inherit">FAQ’s</Typography>
        </Grid>
        <Grid
          className={classes.box}
          item
          md={10}
          sm={11}
          xs={12}
          sx={{
            padding: {
              md: "40px 40px 100px",
              sm: "40px 40px 100px",
              xs: "25px 10px",
            },
          }}
        >
          {faq.map((item, index) => (
            <Grid
              key={index}
              className={classes.map}
              sx={{
                padding: { md: "20px 0px", sm: "20px 0px", xs: "20px 0px" },
              }}
            >
              <Accordion
                className={classes.holder}
                expanded={expanded[index]}
                onChange={() => handleChange(index)}
              >
                <AccordionSummary
                  className={classes.question}
                  expandIcon={
                    <ArrowDropDownIcon
                      className={classes.arrow}
                      sx={{
                        color: expanded[index] ? "#FF4600" : "#FFF",
                      }}
                    />
                  }
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                  sx={{
                    padding: {
                      md: "20px 80px 20px 50px",
                      sm: "20px 40px 20px 50px",
                      xs: "20px 15px 20px 25px",
                    },
                  }}
                >
                  <Typography
                    className={classes.text}
                    sx={{
                      fontSize: { md: "21px", sm: "21px", xs: "16px" },
                      width: { md: "75%", sm: "75%", xs: "100%" },
                    }}
                  >
                    {item.no}.{item.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.answer}>
                  <Grid
                    className={classes.orange}
                    sx={{
                      padding: {
                        md: "80px 40px",
                        sm: "80px 40px",
                        xs: "30px 15px 20px 25px",
                      },
                    }}
                  >
                    <Typography
                      className={classes.text}
                      sx={{
                        fontSize: { md: "21px", sm: "21px", xs: "16px" },
                      }}
                    >
                      {item.answer}
                    </Typography>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  );
};

export default AccordionList;
