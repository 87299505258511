const user = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/user.png";

export const testimonialList = [
  {
    dp: user,
    user: "Jordan Parker",
    comment:
      "I recently used this service and I was blown away by their professionalism and attention to detail. I highly recommend this service to anyone looking for top-notch results and outstanding customer service.",
    rating: 5,
  },
  {
    dp: user,
    user: "Muhammad Ameer",
    comment:
      "From the moment I reached out to them, they were responsive and willing to go the extra mile to ensure my needs were met. They provided me with high-quality work that exceeded my expectations and delivered it on time.  ",
    rating: 5,
  },
  {
    dp: user,
    user: "Carlin Desault",
    comment:
      "Right from the outset, the company displayed a high level of responsiveness and commitment to meeting my needs. They produced exceptional work that surpassed my expectations and delivered it promptly as promised.",
    rating: 5,
  },
];
