import React from "react";
import { Grid, Typography,Button } from "@mui/material";
import { social, contact, explore, explore2 } from "./footerList";
import { useStyles } from "./useStyles";

const logo = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/AMPLogo.svg";

const Footer = ({ pmd, psm, pxs }) => {
  const classes = useStyles();
  return (
    <>
      <Grid
        className={classes.footer}
        container
        sx={{
          flexDirection: { md: "row", sm: "column", xs: "column" },
          padding: {
            md: `${pmd}px 100px 50px`,
            sm: `${psm}px 100px 0px`,
            xs: `${pxs}px 50px 0px`,
          },
        }}
      >
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          sx={{
            padding: {
              md: "0px 20px",
              sm: "80px 0px 20px",
              xs: "40px 0px 20px",
            },
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          <Button sx={{"&:hover":{backgroundColor:"transparent"}}}>
            <img src={logo} alt="" href="/"/></Button>
          
          <Typography className={classes.about}>
            AMProtocolab is a leading software solution company that specializes
            in providing innovative and effective solutions to businesses of all
            sizes.
          </Typography>

          <Grid
            mt={3}
            className={classes.row}
            sx={{ justifyContent: { md: "left", sm: "center", xs: "center" } }}
          >
            {social.map((item, index) => (
              
              <a 
              href={item.link} >
              <img
                className={classes.social}
                key={index}
                src={item.logo}
                alt={item.name}
              />
              </a>
              
            ))}
          </Grid>
        </Grid>

        <Grid
          className={classes.center}
          item
          md={4}
          sm={12}
          xs={12}
          sx={{
            padding: { md: "0px 20px", sm: "20px 0px", xs: "20px 0px" },
            borderRight: {
              md: "1px solid #fff",
              sm: "0px solid",
              xs: "0px solid",
            },
            borderLeft: {
              md: "1px solid #fff",
              sm: "0px solid",
              xs: "0px solid",
            },
            borderTop: {
              md: "0px solid #fff",
              sm: "1px solid #fff",
              xs: "1px solid #fff",
            },
            borderBottom: {
              md: "0px solid #fff",
              sm: "1px solid #fff",
              xs: "1px solid #fff",
            },
            display: { md: "block", sm: "flex", xs: "block" },
          }}
        >
          {contact.map((item, index) => (
            <Grid
              className={classes.contact}
              container
              key={index}
              sx={{
               
                flexDirection: { md: "row", sm: "column", xs: "column" },
              }}
            >
              <Grid item md={4} className={classes.contactImg}>
                <Button href={item.link} disableRipple sx={{"&:hover":{backgroundColor:"transparent"}}}>
                <img src={item.logo} alt={item.caption} />
                </Button>
              </Grid>
              <Grid item md={8} className={classes.info} sx={{display:{md:"flex",sm:"flex"},alignItems:{md:"center",sm:"flex-start"},justifyContent:{md:"center",sm:"flex-start"}}}>
                <Typography variant="inherit">
                  <a href={item.link} style={{color:"#fff",textDecoration:"none"}}>{item.caption}</a>
                  <a href={item.link2} style={{color:"#fff",textDecoration:"none",fontSize:"13px"}}><br />{item.caption2}</a>
                  </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          sx={{
            padding: { md: "0px 20px", sm: "20px 0px", xs: "20px 0px" },
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          <Typography className={classes.explore}>Explore</Typography>
          <Grid className={classes.list} container md={12}>
            <Grid item md={4}>
              {explore.map((item, index) => (
                <Typography my={{ md: 1, sm: 2, xs: 2 }} key={index}>
                  <a href={item.link} className={classes.link}>
                    {item.name}
                  </a>
                </Typography>
              ))}
            </Grid>

            <Grid item md={5}>
              {explore2.map((item, index) => (
                <Typography my={{ md: 1, sm: 2, xs: 2 }} key={index}>
                  <a href={item.link} 
                  className={classes.link}>
                    {item.name}
                  </a>
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Footer;
