import { makeStyles } from "@mui/styles";

const box1 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/bigBox1.png";
const box2 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/bigBox2.png";
const bigBlur = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/bigblur.png";
const bigBall = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/bigBall.png";

export const useStyles = makeStyles((theme) => ({
  approach: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundImage: `url('${bigBlur}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "top left",
  },
  header: {
    color: "#fff",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
  },
  heading: {

    fontWeight: "800",
  },
  box1: {
    // backgroundImage: `url('${box1}')`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center center",
    color: "#fff",
    backgroundSize: "100% auto",
    width: "100%",
  },
  bigBall: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundImage: `url('${bigBall}')`,
    backgroundRepeat: "no-repeat",
    
  },
  box2: {
   // backgroundImage: `url('${box2}')`,
    // backgroundRepeat: "no-repeat",
    // backgroundPosition: "center center",
    // backgroundSize: "cover",
    color: "#fff",
    backgroundSize: "100% auto",
    width: "100%",
  },
}));
