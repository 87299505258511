import { makeStyles } from "@mui/styles";

const bigBall = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/new.png";
const smallBall = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/small.png";


export const useStyles = makeStyles((theme) => ({
  checkout: {
    backgroundImage: `url('${bigBall}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "90% 10%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    textAlign: "start",
  },
  header: {
    color: "#fff",
    padding: "30px 0px",
    backgroundImage: `url('${smallBall}')`,
    backgroundPosition: "70% 10%",
    backgroundRepeat: "no-repeat",
  },
  heading: {  fontWeight: "800" },
  subHeading: { },
  body: { display: "flex", justifyContent: "center" },
  planButton: {
    color: "#fff",
    borderImageSource:
      "linear-gradient(311.65deg, #FFFFFF 10.22%, rgba(255, 255, 255, 0) 90.38%),2",
    borderRadius: "15px",
    padding: "25px 40px ",
    marginBottom: "20px",
    boxShadow: "0px 0px 20px 4px #0000000D",
    width: "100%",
    height: "fit-content",
    "&:focus": { outline: "none" },
  },
  scheme: {
    display: "flex",
    flexDirection: "column",
    textTransform: "capitalize",
  },
  title: { fontSize: "20px" },
  rcmnd: { fontSize: "12px" },
  about: { padding: "10px 0px", fontSize: "13px" },
  price: { fontSize: "28px" },
  lowercase: { fontSize: "16px", textTransform: "lowercase" },
  bill: {
    background:
      "linear-gradient(40.97deg, rgba(0, 0, 0, 0.45) -30.31%, rgba(64, 64, 64, 0) 108.21%)",
    backdropFilter: "blur(11px)",
    color: "#fff",
    "&:focus": { outline: "none" },
    borderRadius: "36px",
    padding: "60px 50px",
    marginBottom: "20px",
    width: "100%",
    minHeight: "100%",
    border: "1px solid #fff",
  },
  summary: { fontSize: "24px" },
  titlePlan: { padding: "15px 0px" },
  pricePlan: { textAlign: "right" },
  divider: { background: "#FFFFFF" },
  due: { fontWeight: "400", fontSize: "20px" },
  right: { textAlign: "right" },
  left: { textAlign: "left" },
  buttonHolder: {
    display: "flex",
    justifyContent: "flex-start",
  },
  continue: {
    color: "#fff",
    backgroundColor: "#D25627",
    borderRadius: "30px",
    textTransform: "capitalize",
    padding: "10px 30px",
    fontSize: "20px",
    "&:hover": {
      border: "1px solid #D25627",
    },
  },
  input: {
    borderRadius: "15px",
    border: "1px solid #FFFFFF",
    background: "transparent",
    "& .MuiOutlinedInput-input": {
      color: "#FFFFFF",
    },
    "& .MuiInputLabel-root": {
      color: "#FFFFFF",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FFFFFF",
      borderRadius: "15px",
    },
  },
  change: {
    color: "#FF7945",
    textTransform: "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#ff5100",
    },
    "&:focus": { outline: "none", color: "#ffc105" },
  },
  payment: { display: "flex", alignItems: "flex-end" },
  info: { display: "flex", justifyContent: "space-between" },
  label: { width: "100%", color: "#fff", padding: "0px 10px" },
  method: {
    width: "42%",
  },
  cards: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    borderRadius: "15px",
    border: "2px solid #FFFFFF",
    background: "transparent",
    padding: "15px",
  },
}));
