import React from "react";
import { Grid, Typography } from "@mui/material";
import { allClients } from "./clientsList";
import { useStyles } from "./useStyles";
import ImageSwiper from "./imageSwiper/ImageSwiper";

const Clients = () => {
  const classes = useStyles();
  return (
    <>
      <Grid container className={classes.client} id="client">
        <Grid item md={12} sm={11} xs={11}>
          <Typography mb={3}>Trusted by our clients</Typography>
          {/* <Grid className={classes.flexHolder} item md={12} sm={12}>
            <Grid
              className={classes.gridHolder}
              item
              md={12}
              sm={12}
              xs={12}
              sx={{
                gridTemplateColumns: {
                  md: "repeat(6,1fr)",
                  sm: "repeat(3,1fr)",
                  xs: "repeat(2,1fr)",
                },
              }}
            >
              {allClients.map((item, index) => (
                <Grid key={index} className={classes.imgHolder}>
                  <img src={item.client} alt="" />
                </Grid>
              ))}
            </Grid>
          </Grid> */}
          <ImageSwiper />
        </Grid>
      </Grid>
    </>
  );
};
export default Clients;
