import React from "react";
import { useState,useEffect,useRef } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { plan, monthNames } from "../../Scheme/Plan/planning";
import { useStyles } from "./useStyles";
import Steps from "../Step/Step";

const SelectPlan = ({ choice, onToCheckout,onToScheme,atFirst,newStep }) => {
  const classes = useStyles();
 const [selectedPlan, setSelectedPlan] = useState(atFirst);
const isFirstRender = useRef(true);

  const choose = (index) => {
    setSelectedPlan(index);
    choice(index);
  };

  const next = () => {
    onToCheckout("Checkout");
  };

  const back = () => {
    onToScheme("Scheme");
  }

useEffect(() => {
  if (isFirstRender.current) {
    isFirstRender.current = false;
    choice(atFirst);
  }
}, [atFirst, choice]);


  const today = new Date();
  const formattedDate = `${today.getDate()} ${
    monthNames[today.getMonth()]
  }, ${today.getFullYear()}`;

  useEffect(() => {
  setSelectedPlan(atFirst);
}, [atFirst,newStep]);


  return (
    <>
      <Grid
        className={classes.selectPlan}
        sx={{
          padding: { md: "100px 100px 100px", sm: "100px", xs: "80px 20px" },
        }}
      >
        <Steps action={back} stage={"1"} />
        <Grid
          className={classes.header}
          sx={{
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          <Typography className={classes.heading} sx={{fontSize: {md:"40px",sm:"40px",xs:"30px"}}}>Select Plan</Typography>
          <Typography className={classes.subHeading} sx={{fontSize: {md:"24px",sm:"24px",xs:"20px"}}}>
            For a complete plan comparison and to view all features visit the
            pricing page.
          </Typography>
        </Grid>
        <Grid
          className={classes.body}
          container
          sx={{
            flexDirection: { md: "row", sm: "column", xs: "column" },
          }}
        >
          <Grid item md={3.5} sm={12} xs={12}>
            {plan?.map((item, index) => (
              <Button
                disableRipple
                key={index}
                onClick={() => choose(index)}
                className={classes.planButton}
                sx={{
                  background:
                    selectedPlan === index
                      ? "linear-gradient(180deg, #A13810 0%, #CD5325 100%)"
                      : "linear-gradient(90deg, #151515 6.67%, rgba(0, 0, 0, 0) 100%)",
                  border: selectedPlan === index ? "none" : "1px solid #fff",
                  padding: {md:"25px 40px",sm:"25px 40px ",xs:"25px 20px"},
                }}
              >
                <Grid
                  className={classes.scheme}
                  sx={{
                    textAlign: { md: "left", sm: "center", xs: "center" },
                  }}
                >
                  <Typography className={classes.title}>
                    {item.title}{" "}
                    {item.recommended ? (
                      <span className={classes.rcmnd}> (Recommended)</span>
                    ) : (
                      ""
                    )}
                  </Typography>

                  <Typography classesName={classes.about}>
                    {item.about}
                  </Typography>
                  {/* <Typography className={classes.price}>
                    ${item.price}
                    <span className={classes.lowercase}>.99 /month</span>
                  </Typography> */}
                </Grid>
              </Button>
            ))}
          </Grid>
          <Grid className={classes.bill} item md={8}>
            <Grid>
              <Typography className={classes.summary}>Order Summary</Typography>
              <Grid container className={classes.titlePlan}>
                <Grid item md={6} sm={6} xs={6}>
                  {plan[selectedPlan].title}
                </Grid>
                <Grid item md={6} sm={6} xs={6} className={classes.pricePlan}>
                  ${plan[selectedPlan].price}.99
                </Grid>
              </Grid>
              <hr className={classes.divider} />

              <Grid container className={classes.titlePlan}>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography className={classes.due}>Due Now</Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6} className={classes.right}>
                  <Typography className={classes.due}>
                    ${plan[selectedPlan].price}.99
                  </Typography>
                </Grid>
              </Grid>

              <Grid className={classes.title}>
                <Typography md={12} mb={12}>
                  Have a Coupon ?
                </Typography>
              </Grid>
            </Grid>

            <Grid>
              <h4>Renewal Summary</h4>
              <Grid container my={4}>
                <Grid item md={6} sm={6} xs={6} className={classes.left}>
                  Date
                </Grid>
                <Grid item md={6} sm={6} xs={6} className={classes.right}>
                  {formattedDate}
                </Grid>
              </Grid>

              <Grid container>
                <Grid item md={6} sm={6} xs={6} className={classes.left}>
                  Amount
                </Grid>
                <Grid item md={6} sm={6} xs={6} className={classes.right}>
                  ${plan[selectedPlan].price}.99
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.buttonHolder} item md={12} py={6}>
              <Button className={classes.continue} onClick={() => next()}>
                Continue
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default SelectPlan;
