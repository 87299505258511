const AW = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/portfolio/AW.png";
const AX = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/portfolio/AX.png";
const OG = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/portfolio/OG.png";
const RP = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/portfolio/RP.png";
const CJ = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/portfolio/CJ.png";

export const projectList = [
  {
    id: 1,
    img: AX,
    title: "Acceleraise",
    tag1:"fundraising",
    tag2:"saas"
  },

  {
    id: 2,
    img: AW,
    title: "Agency Watcher",
    tag1:"analytics",
    tag2:"saas"
  },
  {
    id: 3,
    img: OG,
    title: "OptiGate",
    tag1:"leads-gen",
    tag2:"saas"
  },
  {
    id: 4,
    img: RP,
    title: "Recurlpay",
    tag1:"payment-ecosystem",
    tag2: "saas"
  },
  {
    id: 5,
    img: CJ,
    title: "Client Journey",
    tag1:"client-management",
    tag2: "saas"
  },
];
