import { makeStyles } from "@mui/styles";

const ball = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/glow ball.png";
const blurRed = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/blurRed.png";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
    backgroundImage: `url('${ball}')`,
    backgroundRepeat: "no-repeat",
    zIndex: "1",
  },
  heading: { fontSize: "53px", fontWeight: "700" },
  text: { fontSize: "16px", fontWeight: "400" },
  buttonHolder: {
    color: "#fff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage: `url('${blurRed}')`,
    backgroundRepeat: "no-repeat",
    zIndex: "22",
  },
  imgButton: {
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.1)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  slider: {
    minHeight: "fit-content",
    backgroundColor: "transparent",
    zIndex: "0",
  },
  caption: { color: "#fff" },
  captionText: { fontSize: "16px", fontWeight: "400" },
  bold: { fontSize: "15px", fontWeight: "800" },
}));
