import { Grid } from "@mui/material";
import NavBarDark from "../NavBars/NavBarDark/NavBarDark";
import Footer from "../Footer/Footer";
import Details from "./Details/Details";

const Pricing = () => {

  return (
    <Grid>
      <NavBarDark />
      <Details />
      <Footer pmd={"100"} psm={"20"} pxs={"20"} />
    </Grid>
  );
};
export default Pricing;
