import NavBarDark from "../NavBars/NavBarDark/NavBarDark";
import Footer from "../Footer/Footer";
import Company from "./Company/Company";
import Team from "./Team/Team";

const AboutUs = () => {
  return (
    <>
      <NavBarDark />
      <Company />
      <Team />
      <Footer pmd={"100"} psm={"20"} pxs={"20"} />
    </>
  );
};
export default AboutUs;
