import { makeStyles } from "@mui/styles";

const concave = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/why us.png";
const redBlur = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/redblur.png";

export const useStyles = makeStyles((theme) => ({
  concave: {
    backgroundImage: `url('${concave}')`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
    color: "#fff",
    textAlign: "center",
    position: "relative",
  },
  question: {
    zIndex: "0",
    display: "flex",
    justifyContent: "flex-end",
    position: "absolute",
  },
  redBlur: {
    backgroundImage: `url('${redBlur}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    zIndex: "1",
  },
  header: {
    fontSize: "27px",
    fontWeight: "500",
    marginTop: "150px",
  },
  heading: {
    fontSize: "120px",
    fontWeight: "700",
    textAlign: "center",
  },
  img: {
    zIndex: "2",
    position: "relative",
  },
  saas: {
    bottom: "500px",
    right: "300px",
  },
  customer: {
    bottom: "450px",
    right: "400px",
  },
  quality: { bottom: "350px" },
  security: {
    bottom: "900px",
    left: "300px",
  },
  prices: {
    bottom: "850px",
    left: "350px",
  },
}));
