import { React, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import ImageSwiper from "./ImageSwiper/ImageSwiper";
import { useStyles } from "./useStyles";

const prvs = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/prvs.png";
const nxt = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/nxt.png";

const Portfolio = () => {
  const classes = useStyles();
  const [sliderActions, setSliderActions] = useState(null);

  const handleSliderActions = (actions) => {
    setSliderActions(actions);
  };

  const prvImg = () => {
    if (sliderActions) {
      sliderActions.goToPrev();
    }
  };

  const nxtImg = () => {
    if (sliderActions) {
      sliderActions.goToNext();
    }
  };

  return (
    <>
      <Grid container id="portfolio">
        <Grid
          className={classes.root}
          md={8.5}
          sm={8.5}
          xs={12}
          sx={{
            padding: {
              md: "30px 90px 30px 130px",
              sm: "40px 20px 40px 60px",
              xs: "25px",
            },
          }}
        >
          <Typography className={classes.heading} sx={{textAlign:{md:"left",sm:"left",xs:"center"}}}>Portfolio</Typography>
          <Typography className={classes.text} sx={{textAlign:{md:"left",sm:"left",xs:"justify"}}}>
            Our portfolio showcases a wide array of successful projects we've
            completed for our esteemed clients, including our cutting-edge SaaS
            products in the pipeline.
          </Typography>
        </Grid>

        
        <Grid item className={classes.buttonHolder} md={3.5} sm={3.5} xs={12}>
          <Button className={classes.imgButton} onClick={prvImg} disableRipple>
            <img src={prvs} alt=""></img>
          </Button>
          <Button className={classes.imgButton} onClick={nxtImg} disableRipple>
            <img src={nxt} alt=""></img>
          </Button>
        </Grid>
      </Grid>

      <Grid className={classes.slider} mt={{md:0,sm:0,xs:6}}>
        <ImageSwiper handleSliderActions={handleSliderActions} />
      </Grid>
    </>
  );
};
export default Portfolio;
