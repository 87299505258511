import { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { members } from "./members";

const bkg =      "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/team%20bkg.png";
const linkedIn = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/linkedin.png";
const fb =       "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/fb.png";
const github =   "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/github.png";


const Team = () => {
  const [selected, setSelected] = useState(0); 

  const handleSelection = (index) => {
    setSelected(index);
  };

  const selectedPerson = members[selected];

  return (
    <>
      <Grid
        id="team"
        container
        sx={{
          padding: { md: "0px 70px", sm: "0px 70px", xs: "50px 40px" },
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Grid item md={8} sm={12} xs={12}>
          <Typography
            sx={{
              fontSize: { md: "80px", sm: "80px", xs: "45px" },
              fontWeight: "800",
              color: "#fff",
              textAlign: "center",
            }}
          >
            Our Team
          </Typography>

          <Grid
            container
            sx={{
              justifyContent: "center",
              zIndex: "1",
              backgroundImage: `url('${bkg}')`,
              backgroundRepeat: "no-repeat",
              backgroundSize: {md:"40% auto",sm:"55% auto",xs:"100% auto"},
              backgroundPosition: {
                md: "bottom center",
                sm: "center 52%",
                xs: "85% 47%",
              },
              alignContent: "center",
              display: "flex",
              flexDirection: { md: "row", sm: "column", xs: "column" },
              padding: "0px",
            }}
          >
            <Grid
              item
              md={3}
              sm={12}
              xs={12}
              pt={{ md: 10, sm: 0, xs: 0 }}
              sx={{
                textAlign: { md: "left", sm: "center", xs: "center" },
                zIndex: 7,
                flexWrap: "wrap",
                position: "relative",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "60px", sm: "45px", xs: "30px" },
                  fontWeight: "600",
                  color: "#fff",
                  whiteSpace: { md: "nowrap", sm: "wrap", xs: "wrap" },
                  top: 0,
                  left: 0,
                  right: 0,
                  zIndex: 7,
                }}
              >
                {selectedPerson.fname}
              </Typography>
              <Typography
                sx={{
                  fontSize: { md: "60px", sm: "45px", xs: "30px" },
                  fontWeight: "600",
                  color: "#fff",
                  whiteSpace: { md: "nowrap", sm: "wrap", xs: "wrap" },
                  // position: "absolute",
                  // top: "50%",
                  left: 0,
                  right: 0,
                  // transform: "translateY(-50%)",
                  zIndex: 7,
                }}
              >
                {selectedPerson.lname}
              </Typography>
            </Grid>
            <Grid
              item
              md={6}
              sm={6}
              xs={12}
              sx={{
                paddingBottom:{md:"3px",sm:"10px",xs:"10px"},
                zIndex: 9,
                display: "flex",
                alignItems: "flex-end",
                justifyContent:"center",
                textAlign: "center",
                transform: {
                  md: "scale(1)",
                  sm: "scale(1)",
                  xs: "scale(0.75)",
                },
              }}
            >
              <img
                src={selectedPerson.display}
                alt=""
                style={{
                  width: "400px",
                  height: "400px",
                  zIndex: 10,
                  marginBottom: 0,
                  filter: "grayscale(100%)",
                }}
              />
            </Grid>

            <Grid
              item
              md={3}
              sx={{
                zIndex: "2",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-end",
              }}
            >
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#fff",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                  marginTop: "65px",
                }}
              >
                {selectedPerson.info}
              </Typography>
              <Typography
                sx={{
                  fontSize: "20px",
                  fontWeight: "400",
                  color: "#fff",
                  textWrap: "nowrap",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                }}
              >
                {selectedPerson.info2}
              </Typography>
             { selectedPerson.fb || selectedPerson.github || selectedPerson.linkedIn ?
             <Grid
                mt={2}
                sx={{
                  borderTop: "2px solid red",
                  paddingTop: "15px",
                  textAlign: { md: "right", sm: "center", xs: "center" },
                }}
              >
                {selectedPerson.linkedIn ? (
                  <Button disableRipple href={selectedPerson.linkedIn}>
                    <img src={linkedIn} alt="LinkedIn" />
                  </Button>
                ) : (
                  ""
                )}
                {selectedPerson.fb ? (
                  <Button disableRipple href={selectedPerson.fb}>
                    <img src={fb} alt="Facebook" />
                  </Button>
                ) : (
                  ""
                )}
                {selectedPerson.github ? (
                  <Button disableRipple href={selectedPerson.github}>
                    <img src={github} alt="Github" />
                  </Button>
                ) : (
                  ""
                )}
              </Grid>:""}
            </Grid>
          </Grid>
          <Grid mt={5} sx={{textAlign:"justify"}}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: "400", color: "#fff" }}
            >
              {selectedPerson.about}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        md={12}
        sx={{
          padding: "50px 70px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {members.map((person, index) => (
          <Grid key={index}>
            <Button
              onClick={() => handleSelection(index)}
              disableRipple
              sx={{
                borderRadius: "100%",
                backgroundColor: "transparent",
                "&:focus": { outline: "none", border: "2px solid #A6360B" },
                "&:hover": { outline: "none", border: "2px solid #D25627" },
              }}
            >
              <img
                src={person.img}
                alt={person.fname}
                style={{ borderRadius: "180px" }}
              />
            </Button>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default Team;
