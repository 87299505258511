import { makeStyles } from "@mui/styles";

const ribbonBack = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/ribbon-back.png";

export const useStyles = makeStyles((theme) => ({
  main: {
    textAlign: "center",
    height: "fit-content",
    color: "#fff",
  },
  achieve: {
    fontWeight: "700",
  },
  text: {
    
    fontWeight: "500",
    color: " #FFFFFFA6",
  },
  list: {
    padding:'10px',
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    gap: "20px",
  },
  name: {    
    fontSize: "70px",
    fontWeight: "900",
    color: "#D25627",
    fontFamily:"Segoe UI",
  },
  caption: {
    fontSize: "21px",
    fontWeight: "600",
  },
  ribbon: {
    zIndex: "1",
    backgroundImage: `url('${ribbonBack}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
  },
}));
