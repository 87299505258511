import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid, Button } from "@mui/material";
import { testimonialList } from "./Testimonial Card/TestimonialList";
import "./ImageSwiper.scss";
import TestimonialCard from "./Testimonial Card/TestimonialCard";

const prev = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/prvs.png";
const next = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/nxt.png";

const ImageSwiper = ({ handleSliderActions }) => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const sliderRef = useRef(null);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows:false,
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "0px",
          padding: "0px",
        }}
      >
        <ul style={{ margin: "10px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "30px",
          color: "transparent",
          height: "1px",
        }}
      >
        {i + 1}
      </div>
    ),
  };

  const handleResize = () => {
    if (window.innerWidth > 991) {
      setSlidesToShow(1);
    } else if (window.innerWidth > 768) {
      setSlidesToShow(1);
    } else {
      setSlidesToShow(1);
    }
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    if (handleSliderActions) {
      handleSliderActions({ goToPrev, goToNext });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleSliderActions]);

  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        {testimonialList.map((item, index) => (
          <Grid key={index}>
            <TestimonialCard data={item} index={index} key={index} />
          </Grid>
        ))}
      </Slider>

      
    </>
  );
};

export default ImageSwiper;
