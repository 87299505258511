import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    color: "#fff",
  },
  heading: {
    fontSize: "53px",
    fontWeight: "700",
  },
  text: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#FFFFFFA6",
  },
  buttonHolder: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  imgButton: {
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.1)",
    },
    "&:focus": {
      outline: "none",
    },
  },
  slider: {
    minHeight: "fit-content",
    backgroundColor: "transparent",
    zIndex: "0",
  },
}));
