import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";

const blankCard = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/test/blanck.png";
const star = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Star.png";

const TestimonialCard = ({ data, index }) => {
  const classes = useStyles();
  const { dp, user, comment, rating } = data;
  const ratingStars = Array(rating).fill(null);

  return (
    <Grid className={classes.card} container mt={8} key={index}>
      <Grid className={classes.content} item sx={{padding: {md:"0px 20px",sm:"0px 20px",xs:"0px 10px"},}}>
        <Grid className={classes.stars}>
          {ratingStars.map((index) => (
            <img key={index} src={star} alt="" />
          ))}
        </Grid>
        <Grid className={classes.textHolder}>
          <Typography sx={{ padding: {md:"40px",sm:"40px",xs:"10px"}, fontSize: {md:"14px",sm:"14px",xs:"12px"} }}>
            {comment}
          </Typography>
        </Grid>
        <Grid mb={3} className={classes.center}>
          <img src={dp} alt="" />
          <Typography className={classes.name}>{user}</Typography>
        </Grid>
      </Grid>
      <Grid className={classes.blank}>
        <img src={blankCard} alt="" style={{}} className={classes.full} />
      </Grid>
    </Grid>
  );
};

export default TestimonialCard;
