import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStyles } from "./useStyles";

const ideas = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/ideas.png";
const life = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/life.png";
const enjoy = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/enjoy.png";
const arrow = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/arrow.png";
const process = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/Processing-cuate 2_1.gif";

const Results = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Grid container>
      <Grid
        md={6}
        sm={12}
        xs={12}
        className={classes.blurBack}
        sx={{
          transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(1)" },

          textAlign: { md: "center", sm: "center", xs: "left" },
        }}
      >
        <img
          src={process}
          alt=""
          className={classes.process}
          style={{
            width: isXs ? "100%" : "auto",
            height: isXs ? "100%" : "auto",
          }}
        />
      </Grid>
      <Grid md={6} sm={12} xs={12} my={12} sx={{ color: "#fff" }}>
        <Typography className={classes.text} sx={{fontSize: {md:"53px",sm:"53px",xs:"35px"},}}>
          Simplified Process Outstanding{" "}
          <span className={classes.result}>Results</span>
        </Typography>
        <Grid
          className={classes.imgHolder}
          spacing={0}
          sx={{
            transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(0.5)" },
          }}
        >
          <Grid mt={3} className={classes.holder} sx={{}}>
            <Grid>
              <img src={ideas} alt="" 
              style={{
                width: isXs ? "100%" : "auto",
                height: isXs ? "100%" : "auto",
                }}/>
            </Grid>
            <Grid>
              <img
                src={life}
                alt=""
                style={{ position: "relative", bottom: "30px" }}
              />
            </Grid>
            <Grid>
              <img
                src={enjoy}
                alt=""
                style={{ position: "relative", bottom: "50px" }}
              />
            </Grid>
          </Grid>

          <Grid>
            <img
              src={arrow}
              alt=" "
              style={{ position: "relative", top: "30px" }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Results;
