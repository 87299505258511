import { Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    
}))

const Box1 = () => {
  return (
    <Grid mt={{md:3,sm:1,xs:1}} sx={{ fontSize: {md:"18px",sm:"18px",xs:"14px"} }}>
      <Typography my={{md:5,sm:2,xs:1}} sx={{ fontSize: {md:"26px",sm:"26px",xs:"20px"}, textAlign: "center",fontWeight:"600" }}>
        How we work on delivering your SaaS Apps
      </Typography>

      <Typography sx={{fontSize: {md:"18px",sm:"18px",xs:"14px"} }}>
        Our Ideal way of working on a given SaaS application is to create
        separate frontend and backend apps. This is ideal and better over a
        single monolith serving API & frontend rendering as well. Advantages
        include:
      </Typography>
      <ul>
        <li> Isolated deployments, CI/CD pipelines allow for isolated</li>
        releases
        <li>
          {" "}
          Specially beneficial if you are creating a frontend app with a basic
          theme first and want to change it later on. So changing that won’t
          affect your backend at all{" "}
        </li>
        <li>
          {" "}
          This also helps in bug tracking as reporting software will be
          different Easier to manage application load, resolve bottlenecks etc.
          Separate BE API only apps can serve both CMS and mobile apps.{" "}
        </li>
        <li>
          {" "}
          If need be and there is a valid use case, we can implement
          microservices where several BE API only apps communicate with each
          other to serve API calls as needed by FE apps{" "}
        </li>
        <li>
          {" "}
          Devops wise, its ideal to separately manage the backend and frontend
          instances.
        </li>
      </ul>
      <Typography sx={{fontSize: {md:"18px",sm:"18px",xs:"14px"} }}>
        We try to keep ourselves dynamic yet also at the same standardize our
        development processes so they suit us both in long terms. Code without
        longevity instilled in it has short shell life, which is never the right
        approach. Building SaaS products is often building a lego technic set,
        there are many pieces and various combinations to build the same thing
        with different approaches, but they should be pluggable and detachable
        as needed, to reduce development times. Perfection in development is
        mostly this.
      </Typography>
    </Grid>
  );
};

const Box2 = () => {
  return (
    <>
      <Grid mt={{md:3,sm:1,xs:1}} sx={{ fontSize:{md: "18px",sm:"18px",xs:"14px"} }}>
        <Typography my={{md:5,sm:2,xs:1}} sx={{  fontSize: {md:"26px",sm:"26px",xs:"20px"}, textAlign: "center",fontWeight:"600" }}>
          How we work on delivering your SaaS Apps
        </Typography>

        <Typography sx={{fontSize: {md:"18px",sm:"18px",xs:"14px"} }}>
          Our flow of working on a given SaaS application goes the following
          way:
        </Typography>
        <ul style={{ listStyleType: "number" }}>
          <li>
            Book an introductory meeting where our leads and your side will go
            through the idea, requirements and any other details etc.
          </li>
          <li>
            We then regroup on our end to formally structure what we have
            learned to come up with following:
            <ul style={{ listStyleType: "lower-alpha" }}>
              <li>
                ERD(Entity Relationship Diagram) on set of entities as per
                business rules
              </li>
              <li>
                A project brief that will include the material you will provide
                us, we will add what our team will be for this, who will be
                leading the efforts etc.
              </li>
              <li>
                We will also include week by week and also monthly milestones.
              </li>
              <li>
                {" "}
                A timeline based on given set of functionalities & app level
                deliverables will be set.
              </li>
            </ul>
            <li>
              We will have a meeting with you where we will recap what we
              discussed in our previous meeting, then share what we have come up
              with. If there are more revisions needed in the approach or if
              there are more functionalities needed to be included, we will do
              that as well.
            </li>
            <li>
              A point of contact will be established where a project manager
              will be assigned to own the SaaS product development efforts on
              our end & effectively communicate with someone on your end, so
              that quick communication channels are established.
            </li>
            <li>
              Since we use JIRA for project management, we will create a project
              and log tickets for following:
            </li>
            <ul style={{ listStyleType: "lower-alpha" }}>
              <li>
                {" "}
                Design & Documentation Backend Frontend Since our architectural
                pattern is to create separate backend and frontend apps, so as
                next step, what we do is: Let our designs be completed first,
                there maybe few or more than a few iterations to get the right
                approach.
              </li>
              <li>
                Once we have greenlight on designs from your side, our design
                team and Project Manager would work on creating functional specs
              </li>
              <li>
                Functional specs are categorized into given Frontend screens for
                CMS, Main customer facing website, Mobile apps etc. and Backend
                endpoints needed
              </li>
              <li>
                This works wonderfully as Frontend functional specs go in detail
                to show what specific buttons, behavior to inhibit must be added
                for a given app type
              </li>
              <li>
                Similarly, a rough approximated backend API structure is
                mentioned backend functional specs
              </li>
              <li>
                {" "}
                We let our backend team begin as soon as possible so they can
                log any missing Backend & start finishing APIs{" "}
              </li>
              <li>
                Similarly frontend team begins working on screens and add any
                outstanding tickets in our JIRA project management board
              </li>
              <li>
                All Backend & Frontend Pull Requests(Code changes) are
                merged/deployed to application with code review ensuring
                functional specs requirements are met
              </li>
              <li>
                Functional specs are treated throughout the development as the
                source of truth so that less time is wasted in finding out what
                was a specific requirement
              </li>
              <li>
                {" "}
                It's the job of project management to ensure weekly sprints are
                designed to work with this system and meet specific weekly
                requirements. Most realistic way to meet weekly or specific
                release requirements is to develop in organized fashion.
              </li>
              <li>
                {" "}
                This is precisely what we do for any delays whether because of
                seen or unforeseen circumstances is properly communicated, so
                that both our side and your side are sync to where the app
                stands
              </li>
            </ul>
            <li>
              We generally try to divide requirements into specific releases so
              for example V1 can have core features that allow it to be a
              functional Marketable product that does what it says. Subsequent
              versions can add on top of what exists and add any requirements. 
            </li>
            <li>
              Usually at this point, due to customer feedback, folks change some
              of their functional requirements to meet the new business
              requirements, which is only natural. So our working process is
              geared towards maintaining the development velocity on any new
              changes.
            </li>
            <li>Each new requirement goes through:</li>
            <ul>
              <li>
                Meeting to discuss it (what the change is, rationale, how it can
                be done, initial approach etc.)
              </li>
              <li>Changes in design</li>
              <li> Change in functional specs for frontend and backend apps</li>
              <li>
                {" "}
                Project Manager informing our client on any possible timeline
                change
              </li>
              <li>
                {" "}
                Individual frontend & backend teams logging tickets and
                delivering on those requirements.
              </li>
            </ul>
            <li>
              {" "}
              In the end, for a given version of the SaaS app, we end up
              delivering usually something like this:
            </li>
            <li> A client facing website</li>
            <li> An admin CMS</li>
            <li> Mobile & iOS applications</li>
            <li>
              {" "}
              Any more applications if requested such as separate website that
              is focused towards customer support
            </li>
          </li>
        </ul>
      </Grid>
    </>
  );
};
export { Box1, Box2 };
