const projects = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Homepage/Achievments/projects.png";
const saas = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Homepage/Achievments/saas.png";
const clients = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Homepage/Achievments/clients.png";

export const ourAchievement = [
    {
        id:1,
        img:clients,
        value: "300+",
        caption:"Happy Clients"
    },
    {
        id:2,
        img:projects,
        value:'50',
        caption:"Projects Completed"
    },
    {
        id:3,
        img:saas,
        value:"02",
        caption:"SaaS Products"
    }
]