import React from "react";
import { Grid, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useStyles } from "./useStyles";

const ServiceCard = ({ data, index, isSelected }) => {
  const classes = useStyles();
  const { icon, title, text } = data;
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  return (
    <Grid container key={index} className={classes.carousel}>
      <Grid className={classes.cardHolder} sx={{padding: {md:"0px 50px",sm:"0px 50px",xs:"0px 25px"},}}>
        <Grid
          className={classes.card}
          sx={{
            background: isSelected
              ? "linear-gradient(90deg, #A0370F 6.67%, #D25627 100%)"
              : "linear-gradient(90deg, #151515 6.67%, transparent 100%)",

            height: isXs ? "290px":"364px",
            width: isXs ? "265px" :"345px",
          }}
        >
          <Grid item md={12} sm={12} xs={12} className={classes.content} 
          sx={{
              padding: {md:"0px 60px 0px 30px",sm:"0px 60px 0px 30px",xs:"0px 20px 0px 15px"},
              textAlign:{md:'left',sm:'left',xs:"center"},
               display: 'flex', 
              flexDirection: 'column',
              //alignItems:{md:'left',sm:'left',xs:"center"}
              alignItems: isXs? "center":"left",
              }}>
            <Grid
              className={classes.iconHolder}
              sx={{ backgroundColor: isSelected ? "#202020" : "#D25627" }}
              my={3}
            >
              <img src={icon} alt="" />
            </Grid>

            <Typography className={classes.title}>{title}</Typography>
            <Typography className={classes.text}>{text}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default ServiceCard;
