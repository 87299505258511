import { makeStyles } from "@mui/styles";

const box = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/box.png";

export const useStyles = makeStyles((theme) => ({
  develop: {
    background: "#000000",
    height: "fit-content",
    color: "#FFF",
    display: "flex",
    justifyContent: "center",

  },
  heading: {
    
  },
  apps: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: "20px",
  },
  box: {
    backgroundImage: `url('${box}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    padding: "40px 0px",
  },
  img: {
    display: "flex",
    justifyContent: "center",
  },
  name: {
    display: "flex",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: "400",
  },
  para: {  lineHeight: "28.13px" },
}));
