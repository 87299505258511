export const faq = [
    {
        no:"1",
        question:"If I Have a SaaS idea But Not Enough Finances For Developing it. What Should I Do?",
        answer:"Yes, absolutely. Use our Contact Us to connect with us. We are equipped to offer you guidance and general direction on how we can collaborate, if we go ahead."
    },
    {
        no:"2",
        question:"Can I Pay With Paypal, Wise or Bank Account for purchasing any of our SaaS product or acquire our services?",
        answer:"Yes, you can use any of these methods. If you have an issue, please feel free to Contact Us."
    },
    {
        no:"3",
        question:" How Long For would it take for a Standard Project?",
        answer:"Depends on the project size, audience, needs and features. We will soon be launching a project cost calculator that will allow you to calculate project rough cost estimation."
    },
    {
        no:"4",
        question:"How About Data Security & NDA Agreement",
        answer:"   We will use a standard contract and NDA agreement that will cover these needs."
    },
    {
        no:"5",
        question:"Can We Make something that's not listed in our services?",
        answer:" We have a diverse network of talented partners and collaborators. Rest assured we can connect you to someone who can fulfill your needs."
    },
    

]