import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  contact: {
    height: "500px",
    display: "flex",
    justifyContent: "center",
  },
  talk: {
    display: "flex",
    color: "#fff",
    overflow: "hidden",
  },
  textHolder: {
    display: "block",
    margin: "0px 10px",
  },
  text: {
    fontWeight: "700",
    whiteSpace: "nowrap",
    position: "relative",
    right: "130px",
  },
  body: {
    color: "#fff",
    backdropFilter: "blur(11px)",
    borderRadius: "15px",
    border: "1px solid #FFF",
    background: "rgba(13, 13, 13, 0.90)",
    boxShadow: "0px 8px 5px 0px rgba(0, 0, 0, 0.25)",
    
  },
  heading: {  fontWeight: "700", textAlign: "center" },
input: {
  width: "47%",
  borderRadius: "10px",
  border: "1px solid #FFFFFF",
  background: "transparent",
  "& .MuiOutlinedInput-input": {
    color: "#FFFFFF",
    "&::placeholder": {
      color: "#FFFFFF", 
    },
  },
  "& .MuiInputLabel-root": {
    color: "#FFFFFF",
    marginBottom: "20px",
    transform: "translate(14px, 20px) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -20px) scale(0.75)",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ffff", 
  },
  "&:focus": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d45f32", 
    },
    outline: "none",
  },
},

  inputMsg: {
    mr: 1,
    width: "99%",
    borderRadius: "10px",
    border: "1px solid #FFFFFF",
    background: "transparent",
    "& .MuiOutlinedInput-input": {
    color: "#FFFFFF",
    "&::placeholder": {
      color: "#FFFFFF", 
    },
  },
  "& .MuiInputLabel-root": {
    color: "#FFFFFF",
    marginBottom: "20px",
    transform: "translate(14px, 20px) scale(1)",
    "&.MuiInputLabel-shrink": {
      transform: "translate(14px, -20px) scale(0.75)",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#ffff", 
  },
  "&:focus": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#d45f32", 
    },
    outline: "none",
  },
  },
  
  checkbox: { color: "#FFFFFF80" },
  agree: { fontSize: "16px", fontWeight: "400",flexWrap:"nowrap" },
  f20: { "& .MuiSvgIcon-root": { fontSize: "20px" } },
  submit: {
    color: "#fff",
    fontSize: "22px",
    borderRadius: "30px",
    textTransform: "capitalize",
    padding: "0px 15px",
    border: "1px solid transparent",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#d25627",
      border: "1px solid #d25627",
    },
    "&:focus": {
      outline: "none",
      backgroundColor: "#d25627",
      color: "#fff",
      border: "1px solid #fff",
    },
  },
}));
