import { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { FaCheck } from "react-icons/fa";
import { useStyles } from "./useStyles";


const Plan = ({ data,pick }) => {
  const classes = useStyles();
  const { title, about, price, recommend, service,index } = data;
  return (
    <Grid container
      className={classes.plan}
      sx={{ padding: recommend ? "20px" : "50px 10px 20px",
      
    }}
    >
      <Grid item md={12} sm={12} xs={12}
        className={classes.card}
        sx={{
          padding: {md:"50px 40px",sm:"50px 40px",xs:"25px 15px"},
          background: recommend
            ? "linear-gradient(187.79deg, #E96C3D 5.36%, rgba(255, 70, 0, 0) 154.11%) 14px"
            : "#000000",
          border: recommend ? "1px solid transparent" : "1px solid #000",
          borderRadius: recommend ? "36px" : "15px",
        }}
      >
        <Typography
          className={classes.title}
          sx={{
            fontSize: {md:"24px",sm:"24px",xs:"20px"},
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          {title}
        </Typography>
        <Typography
          className={classes.about}
          sx={{
            
            fontSize: {md:"14px",sm:"14px",xs:"12px"},
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          {about}
        </Typography>
        <Typography
          className={classes.price}
          sx={{
            borderBottom: recommend ? "2px solid #000" : "2px solid #fff",
          }}
        >
          {/* ${price}
          <span className={classes.small}>.99 <span style={{  color: recommend ? "#000" : "#545454" }}> /months
          </span></span> */}
        </Typography>
        <Grid className={classes.subscribe}>
          <Button className={classes.subButton} disableElevation disableRipple href="#"
            onClick={()=>pick(index)}
            sx={{
              fontSize:{md:"16px",sm:'16px',xs:"14px"},
              backgroundColor: recommend ? "#000" : "#D25627",
              "&:hover":{
                backgroundColor: recommend ?  "#D25627":"#000",
                border:recommend ? "2px solid #fff" : " 2px solid #D25627",
              }
            }}
          >
            Subscribe
          </Button>
        </Grid>
        {service?.map(({ text,index }) => (
          <Grid key={index} className={classes.row}>
            <Grid className={classes.check} sx={{ color: recommend ? "#fff" : "green" }}>
              <FaCheck />
            </Grid>
            <Grid sx={{fontSize: {md:"14px",sm:"14px",xs:"12px"}}}>{text}</Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
export default Plan;
