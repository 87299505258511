import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
//    flexGrow: 1,
  },
  appBar: {
    boxShadow: "none",
  },
  logo: {
    backgroundColor: "transparent",
    width: "20%",
  },
  logoButton: {
    "&:hover": {
      backgroundColor: "transparent",
      transform: "scale(1.1)",
    },
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  img: {
    width: "100px",
    height: "63px",
  },
  menuButton: {
    "&:focus": {
      outline: "none",
    },
  },
  title: {
    flexGrow: 1,
  },

  navbar: {
    display: "flex",
    justifyContent: "space-between",
    
  },

  buttonContainer: {
    display: "flex",
    fontWeight: "600",
    textAlign: "center",
    margin: " 10px 0px",
    textDecoration: "none",
    padding: "20px",
    width: "100%",
    color: "#D25627",
    "&:hover": {
      backgroundColor: "#D25627",
      color: "#fff",
    },
  },

  home: {
    marginX: "10px",
  },
  nav: { display: "flex" },
  getStarted: {
    backgroundColor: "#D25627",
    color: "#fff",
    
    fontWeight: "700",
    whiteSpace: "nowrap",
    "&:hover":{
      backgroundColor:"transparent",
      border:"2px solid #D25627",
      color:"#fff"
    }
  },
  navItem: {
    color: "#fff",
    fontSize: "12px",
    width: "100%",
    padding: "10px 15px",
    whiteSpace: "nowrap",
    "&:hover": {
      color: "#fff",
      border: "1px solid #d25627",
    },
    "&:focus": {
      color: "#fff",
      backgroundColor: "#d25627",
    },
  },
  itemHolder: {
    width: "100%",
    justifyContent: "space-evenly",
    postition: "relative",
    left: "20%",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  presentation: {
    backgroundColor: "#0b0b0b",
    display: "flex",
    alignItems: "space-between",
    flexDirection: "column",
    height: "100%",
  },
  navLogo: { marginX: "10px" },
}));
