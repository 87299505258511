import { Grid, Typography } from "@mui/material";
import NavBarDark from "../NavBars/NavBarDark/NavBarDark";
import Footer from "../Footer/Footer";
import Approach from "./Approach/Approach";
const HowWeWork = () =>{
    return(
        <>
        <NavBarDark />
        <Approach />
        <Footer pmd={"100"} psm={"20"} pxs={"20"} />;
        </>
    );
};
export default HowWeWork;