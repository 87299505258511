import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  plan: { color: "#fff" },
  card: { height: "100%" },
  title: { fontWeight: "600", marginBottom: "15px" },
  about: {},
  price: {
    fontSize: "32px",
    fontWeight: "600",
    padding: "10px",
    margin: "10px 0px 25px",
    textAlign: "center",
  },
  small: { fontSize: "20px" },
  subscribe: { display: "flex", justifyContent: "center", margin: "15px 0px" },
  subButton: {
    color: "#fff",
    borderRadius: "30px",
    textTransform: "capitalize",
    padding: "7px 20px",
    "&:hover": { color: "#fff" },
  },
  row: { display: "flex", flexDirection: "row" },
  check: { padding: "5px" },
}));
