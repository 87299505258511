import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  footer: {
    backgroundColor: "#A6360B",
    color: "#fff",
    display: "flex",
    zindex: "0",
  },
  about: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#FFFFFFA6",
    lineHeight: "19.2px",
  },
  row: { display: "flex", flexDirection: "row" },
  social: { marginRight: "20px" },
  center: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "flex-start",
  },
  contact: { display: "flex", justifyContent: "center" },
  contactImg: { padding: "20px 25px" },
  info: {
    padding: "20px 10px",
    fontSize: "16px",
    fontWeight: "400",
    whiteSpace: "pre-line",
  },
  explore: { fontSize: "36px", fontWeight: "700", padding: "0px 30px" },
  list: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
  },
  link: {
    textDecoration: "none",
    color: "#fff",
    textWrap:"nowrap",
    "&:hover": {
      textDecoration: "none",
      color: "#fff",
      fontWeight: "700",
    },
  },
}));
