
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import WorkIcon from "@mui/icons-material/Work";
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ContactsIcon from "@mui/icons-material/Contacts";

import AssessmentIcon from '@mui/icons-material/Assessment';


export const NavItems= [
    {
        id:1,
        name:"ABOUT US",
        link: "/about-us",
        logo : <AutoAwesomeIcon />
    },
    {
        id: 2,
        name: "SERVICES",
        link: "/#services",
        logo : <IntegrationInstructionsIcon />

    },
    {
        id: 3,
        name: "PORTOLIO",
        link: "/#portfolio",
        logo: <WorkIcon />

    },
    {
        id: 4,
        name: "CONTACT",
        link: "/#contact",
        logo:<ContactsIcon />

    },
]


export const NavItemsDark = [
  {
    id: 1,
    name: "HOME",
    link: "/#home",
    logo: <AutoAwesomeIcon />,
  },{
    id: 2,
    name: "ABOUT",
    link: "/about-us",
    logo: <AutoAwesomeIcon />,
  },
  {
    id: 3,
    name: "PORTOLIO",
    link: "/#portfolio",
    logo: <AssessmentIcon />,
  },  
  {
    id: 4,
    name: "CONTACT US",
    link: "/contact",
    logo: <MonetizationOnIcon />,
  },
];
