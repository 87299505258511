import React from "react";
import {
  Grid,
  Typography,
  Button,
  TextField,
  InputLabel,
} from "@mui/material";
import { useStyles } from "./useStyles";
import Steps from "../Step/Step";
import { plan } from "../../Scheme/Plan/planning";

const visa = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Pricing/visa.png";
const master = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Pricing/masterCard.png";
const express = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Pricing/americanExpress.png";

const Checkout = ({choice,onToSelectPlan}) => {
  const classes = useStyles();

  const back = () => {
  onToSelectPlan("SelectPlan");
};

  return (
    <>
    
      <Grid
        className={classes.checkout}
        sx={{
          padding: { md: "100px 100px 100px", sm: "100px", xs: "80px 20px" },
        }}
      >
       <Steps action={back} stage={"2"}/> 
        <Grid
          className={classes.header}
          sx={{
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          <Typography className={classes.heading} sx={{fontSize: {md:"40px",sm:"40px",xs:"30px"}}}>Complete Checkout</Typography>
          <Typography className={classes.subHeading} sx={{fontSize: {md:"24px",sm:"24px",xs:"20px"}}}>
            Check all the details you provided before proceeding.
          </Typography>
        </Grid>
        <Grid
          className={classes.body}
          container
          sx={{
            flexDirection: { md: "row", sm: "column", xs: "column" },
          }}
        >
          <Grid className={classes.bill} item md={8}>
            <Grid container mb={8}>
              <Grid container md={8} sm={12} xs={12} className={classes.info}>
                <Grid item md={6} sm={6} xs={12}>
                  <InputLabel
                    className={classes.label}
                    htmlFor="outlined-basic"
                  >
                    Name
                  </InputLabel>
                  <TextField
                    required
                    id="outlined-basic"
                    placeholder="Your Name"
                    defaultValue=""
                    variant="outlined"
                    className={classes.input}
                    sx={{
                      mr: 1,
                      width: { md: "85%", sm: "85%", xs: "100%" },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item mt={{ md: 0, sm: 0, xs: 3 }} md={6} sm={6} xs={12}>
                  <InputLabel
                    className={classes.label}
                    htmlFor="outlined-basic"
                  >
                    Company
                  </InputLabel>
                  <TextField
                    required
                    id="outlined-basic"
                    placeholder="AMProtocol Labs"
                    defaultValue=""
                    variant="outlined"
                    className={classes.input}
                    sx={{
                      mr: 1,
                      width: { md: "85%", sm: "85%", xs: "100%" },
                    }}
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid
                  item
                  md={6}
                  sm={6}
                  xs={12}
                  mt={5}
                  className={classes.method}
                >
                  <InputLabel
                    className={classes.label}
                    htmlFor="outlined-basic"
                  >
                    Payment Method
                  </InputLabel>
                  <Grid
                    className={classes.cards}
                    md={12}
                    sm={12}
                    xs={12}
                    sx={{width: { md: "85%", sm: "85%", xs: "90%" },}}
                  >
                    <Grid>
                      <img src={master} alt="" />
                    </Grid>
                    <Grid>
                      <img src={express} alt="" />
                    </Grid>
                    <Grid>
                      <img src={visa} alt="" />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item md={6} sm={6} xs={12} mt={5}>
                  <InputLabel
                    className={classes.label}
                    htmlFor="outlined-basic"
                  >
                    Card Number
                  </InputLabel>
                  <TextField
                    required
                    id="outlined-basic"
                    type="password"
                    placeholder="***************583"
                    defaultValue=""
                    variant="outlined"
                    className={classes.input}
                    sx={{ width: { md: "85%", sm: "85%", xs: "90%" } }}
                  />
                </Grid>
              </Grid>
              <Grid md={4} mt={{xs:6}} className={classes.payment}>
                <Button className={classes.change} disableRipple>
                  Change Payment Details
                </Button>
              </Grid>
            </Grid>
            <Grid>
              <Typography className={classes.summary}>Order Summary</Typography>
              <Grid container className={classes.titlePlan}>
                <Grid item md={6} sm={6} xs={6}>
                  {plan[choice].title}
                </Grid>
                <Grid item md={6} sm={6} xs={6} className={classes.pricePlan}>
                  ${plan[choice].price}.99
                </Grid>
              </Grid>
              <hr className={classes.divider} />

              <Grid container className={classes.titlePlan}>
                <Grid item md={6} sm={6} xs={6}>
                  <Typography className={classes.due}>Total Amount</Typography>
                </Grid>
                <Grid item md={6} sm={6} xs={6} className={classes.right}>
                  <Typography className={classes.due}>
                    ${plan[choice].price}.99
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid className={classes.buttonHolder} item md={12} pt={9}>
              <Button className={classes.continue}>Pay Now</Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Checkout;
