import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  carousel: {
    width: "fit-content",
    height: "fit-content",
    backgroundColor: "transparent !important",
  },
  cardHolder: {
    color: "#fff",
    
    width: "fit-content",
    display: "flex",
    flexDirection: "column",
    height: "fit-content",
    backgroundColor: "transparent !important",
  },
  card: {
    display: "flex",
    alignItems: "center",
    borderRadius: "36px",
    border: "1px solid #FFF",
    backdropFilter: "blur(60px)",
  },
  content: {
    backgroundColor: "transparent",
    zIndex: "1",
    
  },
  iconHolder: {
    width: "70px",
    height: "70px",
    padding: "10px",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    mb: "20px",
  },
  title: { fontSize: "21px", fontWeight: "600" },
  text: { fontSize: "16px", fontWeight: "400" },
}));
