import { React, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import ImageSwiper from "./ImageSwiper/ImageSwiper";
import { useStyles } from "./useStyles";

const next = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/nxt.png";
const prev = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/prvs.png";

const Testimonial = () => {
  const classes = useStyles();
  const [sliderActions, setSliderActions] = useState(null);

  const handleSliderActions = (actions) => {
    setSliderActions(actions);
  };

  const prvImg = () => {
    if (sliderActions) {
      sliderActions.goToPrev();
    }
  };

  const nxtImg = () => {
    if (sliderActions) {
      sliderActions.goToNext();
    }
  };

  return (
    <>
      <Grid id="testimonials"
        className={classes.root}
        container
        sx={{
          padding: { md: "0px 70px 80px", sm: "0px 70px 80px", xs: "0px 15px" },
          flexDirection: { md: "row", sm: "column", xs: "column" },
        }}
      >
        <Grid
          className={classes.white}
          item
          md={5}
          sm={12}
          xs={12}
          sx={{
            textAlign: { md: "left", sm: "center", xs: "center" },
          }}
        >
          <Typography className={classes.test} sx={{fontSize: {md:"27px",sm:"27px",xs:"20px"},}}>Testimonials</Typography>
          <Typography className={classes.happy} sx={{fontSize: {md:"53px",sm:"53px",xs:"35px"},}}>
            Hear From Happy Customers
          </Typography>
        </Grid>

        <Grid className={classes.row} item md={7} sm={12} xs={12}>
          <Grid
            className={classes.buttonHolder}
            md={0.5}
            sm={0.5}
            xs={1}
          >
            <Button
              className={classes.button}
              onClick={prvImg}
              disableRipple
              sx={{
                transform:{md:"scale(1)",sm:"scale(1)",xs:"scale(0.75)"},
              "&:hover":{
                transform:{md:"scale(1.1)",sm:"scale(1.1)",xs:"scale(0.85)"}}
            }}>
              <img src={prev} alt="Previous" />
            </Button>
          </Grid>

          <Grid item md={11} sm={11} xs={10}>
            <ImageSwiper handleSliderActions={handleSliderActions} />
          </Grid>

          <Grid
            className={classes.buttonHolder} md={0.5} sm={0.5} xs={1}
            sx={{ justifyContent: "right" }}
          >
            <Button className={classes.button} onClick={nxtImg} disableRipple               sx={{
                transform:{md:"scale(1)",sm:"scale(1)",xs:"scale(0.75)"},
              "&:hover":{
                transform:{md:"scale(1.1)",sm:"scale(1.1)",xs:"scale(0.85)"}}
            }}>
              <img src={next} alt="Next" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default Testimonial;
