import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { useStyles } from "./useStyles";

const selectedCard = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/portfolio/selected.png";
const blankCard = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/portfolio/blank.png";

const PortfolioCard = ({ data, index, isSelected }) => {
  const classes = useStyles();
  const { img, title,tag1,tag2 } = data;

  return (
    <Grid className={classes.carousel} container key={index}>
      <Grid className={classes.cardHolder} 
        sx={{padding: {md:"70px 30px",sm:"70px 30px",xs:"50px 20px"},}}>
        <img
          className={classes.cardBack}
          src={isSelected ? selectedCard : blankCard}
          alt=""
        />
        <Grid
          className={classes.card}
          item
          md={12} sm={12} xs={12}
          sx={{ bottom: { md: "25%", sm: "25%", xs: "20%" } }}
        >
          <Grid
            sx={{
              transform: { md: "scale(1)", sm: "scale(0.8)", xs: "scale(1)" },
            }}
          >
            <img className={classes.img} src={img} alt="" />
          </Grid>
        </Grid>
        <Grid
          className={classes.label}
          sx={{
            padding: {
              md: "0px 30px 20px",
              sm: "20px 20px 20px 20px",
              xs: "20px 20px 20px 20px",
            },
          }}
        >
          <Typography mb={{md:0,sm:2,xs:2}} className={classes.title} 
          sx={{fontSize: {md:"35px",sm:"25px",xs:"22px"},}}>{title}</Typography>
          <Button
            href="#"
            className={classes.button}
            sx={{
              fontSize: {md:"12px",sm:"10px",xs:"9px"},
              "&:hover": {
                backgroundColor: isSelected ? "#0b0b0b" : "#b1451c",
                
              },
            }}
          >
            {tag1}
          </Button>
          <Button
            href="#"
            className={classes.button}
            sx={{
              fontSize: {md:"12px",sm:"10px",xs:"9px"},
              "&:hover": {
                backgroundColor: isSelected ? "#0b0b0b" : "#b1451c",
                
              },
            }}
          >
            {tag2}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PortfolioCard;
