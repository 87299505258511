const myob = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/myob.png";
const belimo = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/belimo.png";
const lifegroups = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/lifegroups.png";
const lilly = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/lilly.png";
const citrus = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/citrus.png";
const trustly = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/trustly.png";


export const allClients = [
    {id: 1, client: myob},
    {id: 2, client: belimo},
    {id: 3, client: lilly},
    {id: 4, client: trustly},
    {id: 5, client: citrus},
    {id: 6, client: lifegroups},
];