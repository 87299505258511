import { Grid, Typography } from "@mui/material";
import { ourAchievement } from "./AcheivementsList";

const us = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/About-us/us.png";
const tilt = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/About-Us/boxTilt.png";

const Company = () => {
  return (
    
      <Grid container sx={{ padding: {md:"150px 70px 0px",sm:"100px 20px 0px",xs:"100px 20px 0px"},justifyContent:"center" }}>
        <Grid item md={7} sm={10} xs={12}>
          <Typography
            sx={{ fontSize: {md:"80px",sm:"80px",xs:"50px"}, fontWeight: "800", color: "#fff",textAlign:{md:"left",sm:"center",xs:"center"} }}
          >
            About Us
          </Typography>
          <Typography my={4}
            sx={{ fontSize: "16px", fontWeight: "400", color: "#fff",textAlign:{md:"left",sm:"left",xs:"center"} }}
          >
            Welcome to the immersive realm of AMProtocol Labs, a distinguished
            haven where ingenuity converges with technological prowess,
            dedicated to the inception and evolution of avant-garde SaaS
            solutions and tailor-made software projects. Our illustrious journey
            commenced in January 2023, guided by the visionary leadership of
            Syed Anab Imam and Hassan Haider, seasoned web developers whose
            expertise is intricately woven into the DNA of our enterprise,
            driving us towards the zenith of industry standards in SaaS product
            development.
          </Typography>
          <Typography
            mt={5}
            sx={{ fontSize: "16px", fontWeight: "400", color: "#fff",paddingRight:{md:"25px"},textAlign:{md:"left",sm:"left",xs:"center"} }}
          >
            Within the vibrant tapestry of AMProtocol Labs, an interdisciplinary
            symphony unfolds, orchestrated by our diverse and dynamic team.
            Comprising accomplished software engineers, visionary designers, and
            meticulous project managers, we synergize our talents to forge a
            collective mission.
          </Typography>
        </Grid>
        <Grid item md={5}  
        sx={{
           display: "flex", 
           justifyContent: "right",
           zIndex:1,
           transform:{md:"scale(1)",sm:"scale(0.75)",xs:"scale(0.5)"} 
          }}>
          <img src={us} alt="" />
        </Grid>      
        <Grid mt={6}
          sx={{
            display: "flex",
            zIndex: 3,
            justifyContent: "center",
            gap: "10px",
            width: "fit-content",
            border: "1px solid #FFFF",
            borderRadius: "36px",
            padding: "35px",
            background: "linear-gradient(90deg, rgba(0, 0, 0, 0.81) 6.67%, rgba(0, 0, 0, 0.67) 100%)",
            backdropFilter: "blur(5px)",
            transform: {
              md: "scale(1) rotate(-5.76deg)",
              sm: "scale(1) rotate(-5.76deg)",
              xs: "scale(0.8) rotate(0deg)"
            },
            position: "relative",
            bottom: {md:"140px",sm:"100px",xs:"100px"}
          }}
          
        >
          {ourAchievement.map((item, index) => (
            <Grid key={index} sx={{ textAlign: "center" }}>
              <img src={item.img} alt="" />
              <Typography
                sx={{ fontSize: {md:"70px",sm:"50px",xs:"40px"}, fontWeight: "900", color: "#D25627" }}
              >
                {item.value}
              </Typography>
              <Typography
                sx={{ fontSize: "21px", fontWeight: "600", color: "#fff" }}
              >
                {item.caption}
              </Typography>
            </Grid>
          ))}
        </Grid>
      
        <Grid sx={{position:"relative", bottom: {md:"110px",sm:"70px",xs:"0px"},textAlign:{md:"justify",sm:"center",xs:"center"}}}>
        <Typography sx={{ fontSize: "16px", fontWeight: "400", color: "#fff" }}>
          AMProtocol Labs is more than an assembly of professionals; it is a
          crucible of creativity and collaboration. Our journey is not only
          about writing code but sculpting the future. We envisage a seamless
          fusion of talent and technology, where ideas are not just
          conceptualized but meticulously transformed into reality.
          Collaborating with visionaries from diverse sectors, we embark on a
          transformative process to materialize their concepts into tangible
          entities—be it through the creation of a Minimum Viable Product (MVP)
          that breathes life into the embryonic stages of an idea, or a Minimum
          Marketable Product (MMP) that crystallizes into a market-ready
          masterpiece.
        </Typography>
        <Typography mt={5} sx={{ fontSize: "16px", fontWeight: "400", color: "#fff" }}>
          Our commitment extends beyond the realms of conventional software
          development; it is a pledge to usher in a new era of innovation, where
          speed meets precision, and collaboration transcends boundaries. Join
          us on this odyssey at AMProtocol Labs, where each line of code, every
          design element, and every project milestone is a testament to our
          unwavering dedication to excellence and the relentless pursuit of
          shaping the future of digital solutions. Together, let us redefine
          what's possible in the ever-evolving landscape of technology and leave
          an indelible mark on the canvas of innovation.
        </Typography>
        </Grid>    
      </Grid>
    
  );
};
export default Company;
