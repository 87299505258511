import { useState, useEffect } from "react";
import {
  Grid,
  AppBar,
  Box,
  Toolbar,
  Button,
  IconButton,
  Drawer,
} from "@mui/material";
import { NavItemsDark } from "../NavItems";
import HomeIcon from "@mui/icons-material/Home";
import { useStyles } from "./useStyles";

const option = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/NavBar/hamBurgLight.png";
const logo = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/NavBar/AMPLogo.svg";

const NavBarDark = () => {
  const classes = useStyles();

  const [transparent, setTransparent] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  useEffect(() => {
    function handleScroll() {
      const isTransparent = window.scrollY === 0;
      setTransparent(!isTransparent);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Box className={classes.root}>
      <AppBar
        className={classes.appBar}
        position="fixed"
        sx={{
          backgroundColor: transparent ? "#0A0A0A" : "transparent",
          opacity: transparent ? "0.95" : "1",
          paddingLeft: { md: "50px", sm: "50px", xs: "0px" },
          paddingRight: { md: "50px", sm: "50px", xs: "0px" },
        }}
      >
        <Toolbar className={classes.navbar} sx={{
          paddingRight: {md:"50px",sm:"30px",xs:"15px"},
          paddingLeft: {md:"80px",sm:"30px",xs:"15px"},
    }}>
          <Grid
            className={classes.logo}
            sx={{
              display: {
                sm: "none",
                md: "block",
                xs: "none",
              },
            }}
          >
            <Button className={classes.logoButton} disableRipple href="/home">
              <img className={classes.img} src={logo} alt="" />
            </Button>
          </Grid>

          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{
              display: {
                sm: "block",
                md: "none",
              },
            }}
          >
            <img src={option} alt="" />
          </IconButton>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "left",
              width: { md: "70%", sm: "57%", xs: "40%" },
            }}
          >
            <Toolbar
              className={classes.itemHolder}
              sx={{
                display: { md: "flex", sm: "none", xs: "none" },
              }}
            >
              {NavItemsDark.map((item) => (
                <Grid className={classes.nav} key={item.id}>
                  <Button
                    disableFocusRipple
                    disableRipple
                    disableElevation
                    className={classes.navItem}
                    variant="text"
                    href={item.link}
                  >
                    {item.name}
                  </Button>
                </Grid>
              ))}
              <Drawer
                anchor="left"
                open={isDrawerOpen}
                onClose={toggleDrawer(false)}
                className={classes.drawer}
              >
                <Grid
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                  className={classes.presentation}
                >
                  <Button
                    disableFocusRipple
                    disableRipple
                    disableElevation
                    className={classes.buttonContainer}
                    href="/home"
                  >
                    <Grid>
                      <Grid md={2} className={classes.home}>
                        <HomeIcon />
                      </Grid>
                      <Grid md={10}>Home</Grid>
                    </Grid>
                  </Button>
                  {NavItemsDark.map((item) => (
                    <Button
                      disableFocusRipple
                      disableRipple
                      disableElevation
                      className={classes.buttonContainer}
                      key={item.id}
                      href={item.link}
                    >
                      <Grid>
                        <Grid md={2} className={classes.navLogo}>
                          {item.logo}
                        </Grid>
                        <Grid md={10}>{item.name}</Grid>
                      </Grid>
                    </Button>
                  ))}
                </Grid>
              </Drawer>
            </Toolbar>
          </Grid>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBarDark;
