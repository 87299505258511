import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  carousel: {
    width: "fit-content",
    height: "fit-content",
  },
  cardHolder: {
    color: "#fff",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: 1,
    position: "relative",
    bottom: "0px",
  },
  cardBack: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
  card: {
    backgroundColor: "transparent",
    zIndex: "1",
    position: "absolute",
    display: "flex",
    alignItems: "flex-end",
    flexDirection: "column",
    width: "80%",
    textAlign: "left",
    padding: "0px 0px 100px 0px",
  },
  img: {
    width: "100%",
    height: "auto",
  },
  label: {
    zIndex: 2,
    position: "relative",
    bottom: "120px",
  },
  title: {  fontWeight: "500",textWrap:"nowrap" },
  button: {
    color: "#fff",
    backgroundColor: "transparent",
    
    fontWeight: "400",
    borderRadius: "30px",
    border: "1px solid #fff",
    textTransform: "capitalize",
    marginRight: "10px",
  },
}));
