import { React, useState } from "react";
import clsx from "clsx";
import { useStyles } from "./useStyle";
import { Grid, Typography, Button } from "@mui/material";

const quality = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/whyUs/quality.png";
const customer = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/whyUs/customer.png";
const prices = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/whyUs/prices.png";
const saas = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/whyUs/saas.png";
const security = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/whyUs/security.png";
const question = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/whyUs/Question.gif";
const blank = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/whyUs/blank.png";

const WhyUs = () => {
  const classes = useStyles();
  return (
    <Grid
      md={12}
      sm={12}
      xs={12}
      className={classes.concave}
      sx={{
        height: { md: "1000px", sm: "800px", xs: "300px" },
      }}
    >
      <Grid
        className={classes.question}
        sx={{
          transform: {
            md: "scale(1) rotate(13.35deg)",
            sm: "scale(0.6) rotate(13.35deg)",
            xs: "scale(0.23) rotate(13.35deg)",
          },
          top: { md: "30px", sm: "0px", xs: "-120px" },
          right: { md: "-50px", sm: "-50px", xs: "-150px" },
        }}
      >
        <img src={question} alt="" />
      </Grid>
      <Grid
        md={12}
        sm={12}
        xs={12}
        sx={{
          position: "relative",
          height: "100%",
          zIndex: "1",
          top: { md: "15%", sm: "10%", xs: "-20%" },
          transform: { md: "scale(1)", sm: "scale(0.65)", xs: "scale(0.25)" },
        }}
      >
        <Grid className={classes.redBlur} sx={{width:"100%"}}>
          <Typography
            className={classes.header}
            sx={{ "&:hover": { color: "#d25627" } }}
          >
            <a
              href="/how-we-work"
              style={{ color: "#fff", textDecoration: "none" }}
            >
              Check why we are the best
            </a>
          </Typography>

          <Typography className={classes.heading}>
            Why
            <br /> Choose
            <br /> Us?
          </Typography>
        </Grid>

        <Grid className={clsx(classes.img, classes.saas)}>
          <img src={saas} alt="" />
        </Grid>

        <Grid className={clsx(classes.img, classes.customer)}>
          <img src={customer} alt="" />
        </Grid>

        <Grid
          className={clsx(classes.img, classes.quality)}
          sx={{
            right: { md: "300px", sm: "300px", xs: "350px" },
            transform: { md: "scale(1)", sm: "scale(1)", xs: "scale(1)" },
          }}
        >
          <img src={quality} alt="" />
        </Grid>

        <Grid className={clsx(classes.img, classes.security)}>
          <img src={security} alt="" />
        </Grid>

        <Grid className={clsx(classes.img, classes.prices)}>
          <img src={prices} alt="" />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default WhyUs;
