import { Grid, Typography } from "@mui/material";
import NavBarDark from "../NavBars/NavBarDark/NavBarDark";
import Footer from "../Footer/Footer";
import Policy from "./Policy";
const PrivacyPolicy=()=>{
    return(
        <Grid sx={{ height: "fit-content", background:"#a6360b"}}>
            <NavBarDark />
            <Policy />
            <Footer pmd={"80"} psm={"20"} pxs={"20"} />;
        </Grid>
    )
}
export default PrivacyPolicy;