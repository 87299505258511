import { useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import { plan } from "./Plan/planning";
import Plan from "./Plan/Plan";
import { useStyles } from "./useStyles";

const ball3 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Pricing/ball3.png";

const Scheme = ({firstChoice,onToSelectPlan}) => {
  const classes = useStyles();
  const [subscribe, setSubscribe] = useState("");

  const handleSubscribe = (index) => {
    setSubscribe(index);
    firstChoice(index);
    onToSelectPlan("SelectPlan")
  };

  return (
    <Grid
      className={classes.scheme}
      container
      sx={{
        padding: { md: "100px", sm: "100px 40px", xs: "100px 15px" },
      }}
    >
      <Grid item md={10} sm={11} xs={11} className={classes.header} >
        <Typography className={classes.heading} 
          sx={{fontSize: {md:"40px",sm:"40px",xs:"30px"},mb:{md:"0px" ,sm:"25px",xs:"25px"}}}>
          Try out one of our service tiers
        </Typography>
        <Typography className={classes.subHeading} sx={{fontSize: {md:"24px",sm:"24px",xs:"20px"}}}>
          With a SaaS focused software house, we understand the need for
          providing prices that work for you by leveraging us as your reliable
          development partner
        </Typography>
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        xs={12}
        className={classes.plan}
        sx={{ flexDirection: { md: "row", sm: "column", xs: "column" } }}
      >
        {plan?.map((item, index) => (
          <Plan data={item} key={index} pick={handleSubscribe}/>
        ))}
      </Grid>

      <Grid item md={12} sm={12} xs={12} className={classes.backBlur}>
        <Grid className={classes.buttonHolder}>
          <Typography className={classes.custom}>
            Need a custom plan?
          </Typography>
          <Button className={classes.contact} href="/#contact" disableElevation>
            Contact Us
          </Button>
        </Grid>
        <Grid className={classes.bkgBall}>
          <img src={ball3} alt="" />
        </Grid>
      </Grid>
    </Grid>
  );
};
export default Scheme;
