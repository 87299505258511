import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    justifyContent: "center",
  },
  dark: {
    backgroundColor: "#060606",
    borderRadius: "39px",
    width: "95%",
    display: "flex",
  },
  heading: {
    
    fontWeight: "700",
    color: "#fff",
  },
  subHeading: {
    fontWeight: "400",
    color: "#fff",
  },
  text: {
    fontSize: "16px",
    fontWeight: "400",
    color: "#fff",
  },
  italics: {
    fontWeight: "700",
    fontStyle: "italic",
  },
  button: {
    fontSize: "12px",
    fontWeight: "700",
    color: "#FFF",
    backgroundColor: "#D25627",
    borderRadius: "10px",
    textTransform: "capitalize",
    "&:hover":{
      color: "#FFF",
      backgroundColor: "transparent",
      border:"1px solid #D25627",
    }
  },
}));
