import { React, useState } from "react";
import { Grid, Typography, Button } from "@mui/material";
import ImageSwiper from "./imageSwiper/ImageSwiper";
import { useStyles } from "./useStyles";

const prvs = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/prvs.png";
const nxt = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/nxt.png";

const Services = () => {
  const classes = useStyles();
  const [sliderActions, setSliderActions] = useState(null);

  const handleSliderActions = (actions) => {
    setSliderActions(actions);
  };

  const prvImg = () => {
    if (sliderActions) {
      sliderActions.goToPrev();
    }
  };

  const nxtImg = () => {
    if (sliderActions) {
      sliderActions.goToNext();
    }
  };

  return (
    <>
      <Grid container id="services" sx={{flexDirection:{md:"row",sm:"row",xs:"column"}}}>
        <Grid item
          className={classes.root}
          md={8.5}
          sm={8.5}
          xs={12}
          sx={{
            padding: {
              md: "60px 90px 50px 150px",
              sm: "40px 20px 40px 60px",
              xs: "25px",
            },
          }}
        >
          <Typography className={classes.heading} sx={{textAlign:{md:"left",sm:"left",xs:"center"}}}>Our Services</Typography>
          <Typography className={classes.text} sx={{textAlign:{md:"left",sm:"left",xs:"justify"}}}>
            We provide a wide range of software solutions to help streamline and
            optimize your business operations. Browse our services below to see
            how we can help you achieve your goals.
          </Typography>
        </Grid>
        <Grid item
          md={3.5}
          sm={3.5}
          xs={12}
          className={classes.buttonHolder}
          py={{md:0,sm:0,xs:6}}
        >
          <Button onClick={prvImg} className={classes.imgButton} disableRipple>
            <img src={prvs} alt=""></img>
          </Button>
          <Button onClick={nxtImg} className={classes.imgButton} disableRipple>
            <img src={nxt} alt=""></img>
          </Button>
        </Grid>
      </Grid>

      <Grid className={classes.slider}>
        <ImageSwiper handleSliderActions={handleSliderActions} />
      </Grid>

      <Grid mt={6} className={classes.caption} sx={{padding: {md:"0px 100px",sm:"0px 100px",xs:"0px 30px"}}}>
        <Typography className={classes.captionText}>
          Want to know more about our services?
          <span className={classes.bold}>
            {" "}
            Explore Services
          </span>
        </Typography>
      </Grid>
    </>
  );
};
export default Services;
