export const plan = [
  {
    index:0,
    title: "Trailblazer Package",
    about:"Kickstart your SaaS journey with our essential features, perfect for startups or small businesses stepping into the digital arena.",
    price: "1499",
    recommend: false,
    service: [
      { id: 1, text: "Product Conceptualization" },
      { id: 2, text: "Dedicated Project Manager" },
      { id: 3, text: "1-1 Support with Project Manager(within 2-3 days)" },
      { id: 4, text: "Weekly Strategy Calls" },
      { id: 5, text: "Designing the User Experience (UX) and User Interface (UI)" },
      { id: 6, text: "Graphic Design" },
      { id: 7, text: "Web Development (Main Website & CMS included)" },
      { id: 8, text: "Mobile Development - iOS & Android" },
      { id: 9, text: "Basic API Integrations" },
      { id: 10, text: "Testing suite on codebase" },
      { id: 11, text: "Basic QA(Quality Assurance of Shipped Features with basic testing)"},
      { id: 12, text: "Basic Technical Documentation of Product Usage" },
      { id: 13, text: "Continuous Integration/Continuous Delivery Pipeline" },
      { id: 14, text: "Deployment (depends on the requirements)" },
      { id: 15, text: "Technical Documentation of Endpoints" },
      { id: 16, text: "Maintenance (depends on the requirements)" },
    ],
  },

  {
    index:2,
    title: "Vanguard Package",
    about:"Accelerate your growth with our advanced features, including premium support and customization options, designed for businesses ready to scale up.",
    recommended: true,
    price: "2499",
    recommend: true,
    service: [
      { id: 1, text: "Product Conceptualization" },
      { id: 2, text: "Dedicated Project Manager" },
      { id: 3, text: "1-1 Support with Project Manager(within 2-3 days)" },
      { id: 4, text: "Weekly Strategy Calls" },
      { id: 5, text: "Designing the User Experience (UX) and User Interface (UI)" },
      { id: 6, text: "Graphic Design" },
      { id: 7, text: "Web Development (Main Website & CMS(Content Management System i.e. Admin Panel) included)"},
      { id: 8, text: "Mobile Development - iOS & Android" },
      { id: 9, text: "Basic to Advanced level API Integrations" },
      { id: 10, text: "Assist with Infrastructure Scalability as needed" },
      { id: 11, text: "Testing suite on codebase" },
      { id: 12, text: "Medium to Advance QA(Quality Assurance of Shipped Features with functionality tests"},
      { id: 13, text: "Proper Technical Documentation of Product Usage" },
      { id: 14, text: "Continuous Integration / Continuous Delivery pipeline" },
      { id: 15, text: "Deployment (depends on the requirements)" },
      { id: 16, text: "Technical Documentation of Endpoints" },
      { id: 17, text: "Maintenance (depends on the requirements)" },
      { id: 18, text: "Content writing" },
      { id: 19, text: "Copywriting" },
    ],
  },
  
  {
    index:2,
    title: "PaceSetter Package",
    about: "Lead the pack with unrestricted access to all our innovative features and top-tier support. This comprehensive package is tailored to fulfill the demands of established businesses and large enterprises.",
    price: "4999",
    recommend: false,
    service: [
      { id: 1, text: "Product Conceptualization" },
      { id: 2, text: "Dedicated Project Manager"},
      { id: 3, text: "1-1 Support (Highest Priority Support - within 2-3 hours)" },
      { id: 4, text: "Weekly Strategy Calls" },
      { id: 5, text: "Dedicated Project Manager"},
      { id: 6, text: "Designing the User Experience (UX) and User Interface (UI)" },
      { id: 7, text: "Graphic Design" },
      { id: 8, text: "Web Development (Main Website & CMS included)" },
      { id: 9, text: "Mobile Development - iOS & Android" },
      { id: 10, text: "Covers almost any type of API Integrations" },
      { id: 11, text: "Testing suite on codebase"},
      { id: 12, text: "Advanced QA(Quality Assurance of Shipped Features with end-to-end QA of entire app)" },
      { id: 13, text: "Proper Technical Documentation of Product Usage" },
      { id: 14, text: "Continuous Integration / Continuous Delivery pipeline" },
      { id: 15, text: "Deployment (depends on the requirements)" },
      { id: 16, text: "Technical Documentation of Endpoints" },
      { id: 17, text: "Scalability" },
      { id: 18, text: "Monitoring and Maintenance" },
      { id: 19, text: "Maintenance (depends on the requirements)" },
      { id: 20, text: "Content writing" },
      { id: 21, text: "Copywriting" },
      { id: 22, text: "SEO"},
      { id: 23, text: "Financial Consulting on Business Model(through our trusted business partners at get FPA)"},
    ],
  },
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
