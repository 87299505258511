const designRush = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/partner/designRush.png";
const trustPilot = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/partner/trustPilot.png";
const goodFirms = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/partner/goodfirms.png";
const selectedFirms = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/partner/selectedFirms.png";

export const partners=[
    {
        id:1,
        name:"DesignRush",
        img:designRush,
        link:"https://www.designrush.com/agency/profile/amprotocols-labs"
    },
    {
        id:2,
        name:"Good Firms",
        img:goodFirms,
        link:"https://www.goodfirms.co/company/amprotocols-labs"
    },
    {
        id:1,
        name:"Trust Pilot",
        img:trustPilot,
        link:"https://www.trustpilot.com/review/www.amprotocolab.com"
    },
    {
        id:1,
        name:"Selected Firms",
        img:selectedFirms
    },
]