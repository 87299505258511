import React from "react";
import {
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import Pricing from "./components/Pricing/Pricing";
import TechStack from "./components/TechStack/TechStack";
import HowWeWork from "./components/HowWeWork/HowWeWork";
import FAQ from "./components/FAQ/FAQ";
import { useStyles } from "./useStyles";
import AboutUs from "./components/About Us/AboutUs";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";


function App() {
  const classes=useStyles();

  return (
    <div className={classes.body} >
      <Routes>
        <Route path="/" index element={<HomePage />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/technology" element={<TechStack />} />
        <Route path="/how-we-work" element={<HowWeWork />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </div>
  );
}

export default App;
