import React from "react";
import { Grid, Typography } from "@mui/material";
import { useStyles } from "./useStyles";
import { Video } from "./video";

const WhatWeWork = () => {
  const classes = useStyles();

  return (
    <>
      <Grid container className={classes.whatWork}>
        <Video />

        <Grid item className={classes.textContainer} md={12} sm={12} xs={12}>
          
            <Typography className={classes.heading}
              sx={{
                fontSize: { md: "55px", sm: "40px", xs: "35px" },
                textAlign: { md: "center", sm: "center", xs: "center" },
              }}
            >
              What We Work On
            </Typography>
            <Grid className={classes.holder}
              mt={3}
              md={8}
              sm={12}
              xs={12}
            >
              <Typography className={classes.subHeading}
                sx={{
                  fontSize: { md: "20px", sm: "18px", xs: "15px" },
                  textAlign: { md: "center", sm: "center", xs: "center" },
                }}
              >
                Discover our expertise in developing a wide range of powerful
                SaaS applications tailored to meet your business need
              </Typography>
            </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default WhatWeWork;
