import * as React from "react";
import {
  Grid,
  Typography,
  Button,
  Box,
  Stepper,
  Step,
  StepLabel,
  StepIcon,
} from "@mui/material";

const step = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/step.png";
const stepActive = "https://bucketeer-72eb242d-1d7b-420e-a5e7-0d0d7b99949b.s3.amazonaws.com/public/Pricing/stepActive.png";
const back = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/prvs.png";

const Steps = ({ action,stage }) => {
  return (
    <Grid
      container
      sx={{
        display: { md: "flex", sm: "flex", xs: "flex" },
        justifyContent: "space-between",
      }}
    >
      <Grid item md={1.15} sm={1} xs={6}>
        <Button
          disableRipple
          onClick={() => action()}
          sx={{
            transform:{md:"scale(1)",sm:"scale(1)",xs:"scale(0.75)"},
            "&:hover": { backgroundColor: "transparent" },
            "&:focus": { outline: "none" },
          }}
        >
          <img src={back} alt="previous" />
        </Button>
      </Grid>
      {/* <Grid item md={10.85} sm={11} xs={11}>
        <HorizontalLinearAlternativeLabelStepper stage={stage} />
      </Grid> */}
    </Grid>
  );
};
export default Steps;

const steps = ["Select Package", "Select Plan", "Checkout"];

const HorizontalLinearAlternativeLabelStepper = ({stage}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [state,setState]=React.useState(0);
  const handleStepClick = (index) => {
  setActiveStep(index);

  console.log("Stage:", stage);
};


  return (
    <Box sx={{ width: "100%", color: "#fff" }}>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label, index) => (
          <Step key={index} onClick={() => handleStepClick(index)}>
            <StepLabel
              classes={{
                active: "active-step",
                completed: "completed-step",
              }}
              StepIconComponent={CustomStepIcon}
            >
              <Typography sx={{ color: "#fff" }}>{label}</Typography>
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
};

// CustomStepIcon component to customize the color of StepIcon
const CustomStepIcon = (props) => {
  const { active, completed } = props;

  return (
    <Grid
      disableRipple
      sx={{
        "&:focus": { outline: "none" },
        "&:hover": { backgroundColor: "transparent" },
      }}
    >
      {active || completed ? (
        <img src={stepActive} alt="" />
      ) : (
        <img src={step} alt="" />
      )}
    </Grid>
  );
};
