import { makeStyles } from "@mui/styles";

const redBlur = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Tech/redEllipse.png";

export const useStyles = makeStyles((theme) => ({
  stack: {
    position: "relative",
    overflow: "hidden",
    backgroundImage: `url('${redBlur}')`,
    backgroundRepeat: "no-repeat",
    position: "relative",
    zIndex: "1",
  },
  header: {
    color: "#FFF",
    textAlign: "center",
  },
  heading: { fontWeight: "600" },
  img: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    position: "relative",
    zIndex: "10",
  },
}));
