import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  whatWork: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    height: "fit-content",
  },
  textContainer: {
    position: "absolute",
    zIndex: 1,
    height: "fit-content",
    alignItems: "center",
    flexDirection: "column",
    display: "flex",
    flexDirection: "column",
    padding: "100px 0px ",
    background: "transparent",
    color: "#fff",
    [theme.breakpoints.down("md")]: {
      padding: "100px 40px ",
      justifyContent: "left",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "100px 30px ",
      justifyContent: "center",
    },
    [theme.breakpoints.down("xs")]: {
      padding: "80px 0px 30px 70px",
      justifyContent: "center",
    },
  },
  heading: {
    fontWeight: "700",
    lineHeight: "64.61px",
  },
  holder:{
    display: "flex", 
    justifyContent: "center"
  },
  subHeading:{
    fontWeight: "500"
  },
  play: {
    backgroundColor: "#5204F8",
    display: "none",
    color: "#fff",
    padding: "1% 2%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: "30",
  },
  videoContainer: {
    position: "relative",
    width: "100%",
    height: "100vh",
    overflowY: "hidden",
    opacity: "0.8",
  },
  video: {
    position: "absolute",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    autoplay: "true",
  },
}));
