import { makeStyles } from "@mui/styles";

const redBlur = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Pricing/redBlur.png";
const ball2 = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Pricing/ball2.png";

export const useStyles = makeStyles((theme) => ({
  scheme: {
    height: "fit-content",
    backgroundImage: `url('${redBlur}')`,
    backgroundRepeat: "no-repeat",
    justifyContent: "center",
  },
  header: { color: "#fff", textAlign: "center" },
  heading: {  fontWeight: "800" },
  subHeading: {  fontWeight: "400" },
  plan: {
    display: "flex",
    paddingTop: "40px",
    height: "100%",
    width: "100%",
    zIndex: "3",
    backgroundColor: "transparent",
  },
  backBlur: {
    backgroundImage: `url('${ball2}')`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "bottom right",
    padding: "0px",
    position: "relative",
    height: "200px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    zIndex: "0",
    bottom: "50px",
  },
  buttonHolder: {
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    alignItems: "center",
  },
  custom: { fontSize: "24px" },
  contact: {
    backgroundColor: "#D25627",
    color: "#fff",
    borderRadius: "30px",
    textTransform: "capitalize",
    width: "fit-content",
    padding: "7px 20px",
    marginTop: "35px",
    "&:hover":{
      color: "#fff",
      border:"2px solid #D25627",
    }
  },
  bkgBall: { position: "absolute", bottom: 0, right: 0 },
}));
