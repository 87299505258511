import { Grid, Typography } from "@mui/material";
import { Box1, Box2 } from "./TextBox";
import { useStyles } from "./useStyles";

const Approach = () => {
  const classes = useStyles();
  return (
    <>
      <Grid
        className={classes.approach}
        container
        sx={{
          padding: {
            md: "100px 100px 0px",
            sm: "100px 40px 0px",
            xs: "50px 20px 0px",
          },
        }}
      >
        <Grid className={classes.header} item md={11} sm={11} xs={11}>
          <Typography className={classes.heading} mt={{xs:4}} sx={{ fontSize: {md:"40px",sm:"40px",xs:"25px"}}}>HOW WE WORK ?</Typography>
          <Typography sx={{ fontSize: { md: "24px", sm: "24px", xs: "15px" },paddingTop:{md:"15px",sm:"15px",xs:"15px"} }}>
            We take a mix of tried and tested approaches in delivering your
            product with your specifications. Here we take you briefly through
            our work approach.
          </Typography>
        </Grid>

        <Grid
          className={classes.box1}
          item
          my={{md:12,sm:6,xs:6}}
          md={11}
          sm={11}
          xs={12}
          sx={{
            padding: {
              md: "60px 75px",
              sm: "40px 30px",
              xs: "30px 12px",
              backgroundColor: "transparent",
              border: "1px solid #fff",
              borderRadius: "36px",
              backdropFilter: "blur(22px)"
            },
          }}
        >
          <Box1 />
        </Grid>
      </Grid>

      <Grid container
        className={classes.bigBall}
        sx={{
          padding: {
            md: "10px 100px 50px",
            sm: "0px 20px",
            xs: "10px 10px ",
          },
          backgroundPosition: {md:"100% 120%",sm:"100% 110%",xs:"100% 105%"},
        }}
      >
        <Grid 
          className={classes.box2}
          item
          my={{md:1,sm:5,xs:9}}
          md={11}
          sm={11}
          xs={12}
          sx={{
            padding: {
              md: "60px 75px",
              sm: "40px 30px",
              xs: "30px 12px"
            },
            backgroundColor: "transparent",
            border: "1px solid #fff",
            borderRadius: "36px",
            backdropFilter:"blur(22px)"
          }}
        >
          <Box2 />
        </Grid>
      </Grid>
    </>
  );
};
export default Approach;
