const fb = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/fb.svg";
const twitter = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/twitter.svg";
const linkedin = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/linkedIn.svg";
const group = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/group.svg";
const map = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/map.svg";
const phone = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/phone.svg";
const gmail = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/footer/gmail.svg";


export const social = [
  
  {
    id: 1,
    name: "Facebook",
    logo: fb,
    link:"https://www.facebook.com/amprotocollabs",
  },
  {
    id: 2,
    name: "Twitter",
    logo: twitter,
    link:"https://twitter.com/AMProtocolLabs",
    
  },
  {
    id: 3,
    name: "Facebook Community Group",
    logo: group,
    link:"https://www.facebook.com/groups/saashacksandtips",
    
  },
  {
    id: 4,
    name: "LinkedIn",
    logo: linkedin,
    link:"https://www.linkedin.com/company/amprotocol-labs",
    
  },

];

export const contact = [
  {
    id: 1,
    caption: "R-331, Sector 15-A/1, Bufferzone, Karachi 75850, Pakistan",
    logo: map,
    link:"https://maps.app.goo.gl/KrnVPgf21kT5bcsD7"
  },
  {
    id: 2,
   // caption: "555-123-4567\n555-123-4567",
    caption:"+92 334 3551128",
    logo: phone,
    link:"tel:+923343551128",
  },
  {
    id: 3,
    caption: "anab@amprotocolabs.com",
    caption2:"community@amprotocolabs.com",
    logo: gmail,
    link:"mailto:anab@amprotocolabs.com",
    link2:"mailto:community@amprotocolabs.com"
  },
];

export const explore = [
  {
    id: 1,
    name:"Home",
    link:"/#home"
  },
  {
    id: 2,
    name:"About Us",
    link:"/about-us"
  },
  {
    id: 3,
    name:"Services",
    link:"/#services"
  },
  {
    id: 4,
    name:"Pricing",
    link:"/pricing"
  },
  {
    id: 5,
    name:"Portfolio",
    link:"/#portfolio"
  },
  {
    id: 6,
    name:"Contact Us",
    link:"/#contact"
  }
]
export const explore2=[
  {
    id: 1,
    name:"Our Team",
    link:"/about-us#team"
  },
  {
    id: 2,
    name:"Testimonials",
    link:"/#testimonials"
  },
  {
    id: 3,
    name:"Clientele",
    link:"/#client"
  },
  {
    id: 4,
    name:"FAQ",
    link:"/faq"
  },
  {
    id: 5,
    name:"What We Work On",
    link:"/technology"
  },

  {
    id: 6,
    name:"How We Work",
    link:"/how-we-work"
  },
  {
    id: 6,
    name: "Privacy Policy",
    link: "/privacy-policy"
  },
];
