import React from "react";
import { useState, useRef } from "react";
import { Grid, Button } from "@mui/material";
import { useStyles } from "./useStyles";
import { PlayArrow, Pause } from "@mui/icons-material";

const gif = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/gif.png";
const myVid = "https://bucketeer-679e664e-f981-44d3-81f8-77c1c8630547.s3.amazonaws.com/public/Homepage/myVideo.mp4";

export const Video = () => {
  const classes = useStyles();
  const videoRef = useRef(null);

  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    if (videoRef.current.paused) {
      videoRef.current.play();
      setIsPlaying(true);
    } else {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  };

  return (
    <Grid className={classes.videoContainer}>
      <video
        className={classes.video}
        ref={videoRef}
        loop
        muted
        poster={gif}
        autoPlay
      >
        <source src={myVid} type="video/mp4" />
      </video>
      <Button
        sx={{ opacity: isPlaying ? "0" : "0.1" }}
        variant="contained"
        className={classes.play}
        onClick={handlePlay}
      >
        {isPlaying ? (
          <Pause fontSize="large" />
        ) : (
          <PlayArrow fontSize="large" />
        )}
      </Button>
    </Grid>
  );
};
