import React, { useState, useEffect, useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Grid} from "@mui/material";
import { projectList } from "./Portfolio Card/projectList";
import "./ImageSwiper.scss";
import PortfolioCard from "./Portfolio Card/PortfolioCard";


const ImageSwiper = ({ handleSliderActions }) => {
  const [slidesToShow, setSlidesToShow] = useState(1);
  const sliderRef = useRef(null);
  const [currentSelectedItemIndex, setCurrentSelectedItemIndex] = useState(1);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 9000,
    pauseOnHover: true,
    backgroundColor: "transparent",
    appendDots: (dots) => (
      <div
        style={{
          backgroundColor: "transparent",
          borderRadius: "0px",
          padding: "0px",
        }}
      >
        <ul style={{ margin: "10px" }}>{dots}</ul>
      </div>
    ),
    customPaging: (i) => (
      <div
        style={{
          width: "30px",
          color: "transparent",
          height: "1px",
        }}
      >
        {i + 1}
      </div>
    ),
    afterChange: (index) => {
      
      const selectItem = (index) % projectList.length;
      if (window.innerWidth > 991) {
     setCurrentSelectedItemIndex(selectItem + 1);
    } else if (window.innerWidth > 768) {
      setCurrentSelectedItemIndex(selectItem + 1);
    } else {
     setCurrentSelectedItemIndex(selectItem);
    }
     
    },
  };

  const handleResize = () => {
    if (window.innerWidth > 991) {
      setSlidesToShow(2.6);
    } else if (window.innerWidth > 768) {
      setSlidesToShow(2.3);
    } else {
      setSlidesToShow(1);
    }
  };

  const goToPrev = () => {
    sliderRef.current.slickPrev();
  };

  const goToNext = () => {
    sliderRef.current.slickNext();
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    if (handleSliderActions) {
      handleSliderActions({ goToPrev, goToNext });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleSliderActions]);

  return (
    <>
      <Slider ref={sliderRef} {...settings}>
        {projectList.map((item, index) => (
          <Grid key={index} className="imageslide-container">
            <PortfolioCard
              data={item}
              index={index}
              key={index}
              isSelected={index === currentSelectedItemIndex}
            />
          </Grid>
        ))}
      </Slider>
    </>
  );
};

export default ImageSwiper;
